import { computed } from "mobx";
import { harmfulRAISegment } from "../../../constants/constants";
import type { PassFailRateByQuery } from "../helpers/lmchecklistMetricsHelper";
import type { LMChecklistAssertion } from "../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetails } from "../models/LMChecklistDetailsResponse";
import type { LMChecklistIcmsResponse } from "../models/LMChecklistIcmsResponse";
import type { PassFailRate } from "../models/LMChecklistPassFailRates";
import type {
  LMChecklistAssertionWithIcm,
  LMCheckListSortKeyType,
} from "../store/jobDetailStore";
import {
  AssertionStatus,
  jobDetailStore,
  LMCheckListSortKeys,
} from "../store/jobDetailStore";

const sortQueryData = (filteredQueryData: LMChecklistDetails[]) => {
  const sorting = jobDetailStore.sorting;
  const sortedQueryData = [...filteredQueryData].sort(
    (a: LMChecklistDetails, b: LMChecklistDetails) => {
      let valA = a[sorting.field as keyof LMChecklistDetails];
      let valB = b[sorting.field as keyof LMChecklistDetails];

      if (valA === undefined) {
        return 1;
      } else if (valB === undefined) {
        return -1;
      }

      if (!isNaN(Number(valA)) && !isNaN(Number(valB))) {
        // Floats may not be sorted correctly with localeCompare()
        valA = parseFloat(valA.toString());
        valB = parseFloat(valB.toString());
        return (valA - valB) * (sorting.ascending ? 1 : -1);
      }

      return (
        valA
          .toString()
          .toLowerCase()
          .localeCompare(valB.toString().toLowerCase(), "en", {
            numeric: true,
          }) * (sorting.ascending ? 1 : -1)
      );
    },
  );
  return sortedQueryData;
};

const sortpfRatesByQuery = (filteredpfRatesByQuery: PassFailRateByQuery[]) => {
  const sorting = jobDetailStore.sorting;
  const sortedpfRatesByQuery = [...filteredpfRatesByQuery].sort(
    (a: PassFailRateByQuery, b: PassFailRateByQuery) => {
      let valA = a[sorting.field as keyof PassFailRateByQuery];
      let valB = b[sorting.field as keyof PassFailRateByQuery];

      if (valA === undefined) {
        return 1;
      } else if (valB === undefined) {
        return -1;
      }

      if (
        LMCheckListSortKeys.includes(sorting.field as LMCheckListSortKeyType)
      ) {
        valA = valA as PassFailRate;
        valB = valB as PassFailRate;

        const pfRateA = valA.passed / valA.total;
        const pfRateB = valB.passed / valB.total;

        return (pfRateA - pfRateB) * (sorting.ascending ? 1 : -1);
      }

      return (
        valA
          .toString()
          .toLowerCase()
          .localeCompare(valB.toString().toLowerCase(), "en", {
            numeric: true,
          }) * (sorting.ascending ? 1 : -1)
      );
    },
  );
  return sortedpfRatesByQuery;
};
export const getEmptylmCheckListFilters = computed(() => {
  const lmCheckListFilters = jobDetailStore.lmCheckListFilters;
  return (
    lmCheckListFilters.segment.length === 0 &&
    lmCheckListFilters.query === "" &&
    !lmCheckListFilters.incompleteDataOnly &&
    !lmCheckListFilters.assertionsWithIcmOnly &&
    !lmCheckListFilters.assertionsWithoutIcmOnly &&
    !lmCheckListFilters.raiContent
  );
});

const getDetailsWithIcm = computed(() => {
  const icms = jobDetailStore.lmchecklistIcmsResponse;
  const details = jobDetailStore.lmchecklistDetailsResponse ?? [];
  const icmMap = new Map<string, LMChecklistIcmsResponse>();
  if (icms !== undefined) {
    icms.forEach((icm) => {
      if (!icmMap.has(icm.QueryHash)) {
        icmMap.set(icm.QueryHash, []);
      }
      icmMap.get(icm.QueryHash)?.push(icm);
    });
  }

  return details.map((detail) => {
    const icm = icmMap.get(detail.queryHash);
    return { ...detail, icms: icm };
  });
});

const getPFRatesByQueryWithIcm = computed(() => {
  const icms = jobDetailStore.lmchecklistIcmsResponse;
  const pfRatesByQuery = jobDetailStore.pfRatesByQuery;
  const icmMap = new Map<string, LMChecklistIcmsResponse>();
  if (icms !== undefined) {
    icms.forEach((icm) => {
      if (!icmMap.has(icm.QueryHash)) {
        icmMap.set(icm.QueryHash, []);
      }
      icmMap.get(icm.QueryHash)?.push(icm);
    });
  }

  return pfRatesByQuery.map((pfRate) => {
    const newIcms = icmMap.get(pfRate.queryHash);
    return { ...pfRate, icms: newIcms };
  });
});

export const getFilteredQueryData = computed((): LMChecklistDetails[] => {
  if (getEmptylmCheckListFilters.get()) {
    return sortQueryData(getDetailsWithIcm.get());
  }

  const lmCheckListFilters = jobDetailStore.lmCheckListFilters;
  const pfRatesByQuery = jobDetailStore.pfRatesByQuery;
  const filterData = getDetailsWithIcm.get().filter((record) => {
    const segmentFilter =
      lmCheckListFilters.segment.length === 0
        ? true
        : lmCheckListFilters.segment.includes(record.segment);

    const queryFilter = record.query
      .toLowerCase()
      .includes(lmCheckListFilters.query.toLowerCase());

    const incompleteDataFilter = () => {
      if (lmCheckListFilters.incompleteDataOnly) {
        const pfRateRecord = pfRatesByQuery.find(
          (pfRate) => pfRate.queryHash === record.queryHash,
        );

        if (pfRateRecord) {
          for (const key of LMCheckListSortKeys) {
            const pfRate = pfRateRecord[key];
            if (pfRate !== undefined && pfRate.missing > 0) {
              return true;
            }
          }
        }
        return false;
      }
      return true;
    };

    const assertionsWithIcmOnlyFilter = lmCheckListFilters.assertionsWithIcmOnly
      ? record.icms !== undefined
      : true;
    const assertionsWithoutIcmOnlyFilter =
      lmCheckListFilters.assertionsWithoutIcmOnly
        ? record.icms === undefined
        : true;
    const raiContentFilter = lmCheckListFilters.raiContent
      ? record.segment !== harmfulRAISegment
      : true;

    return (
      segmentFilter &&
      queryFilter &&
      incompleteDataFilter() &&
      assertionsWithIcmOnlyFilter &&
      assertionsWithoutIcmOnlyFilter &&
      raiContentFilter
    );
  });
  return sortQueryData(filterData);
});

export const getFilteredpfRatesByQuery = computed((): PassFailRateByQuery[] => {
  if (getEmptylmCheckListFilters.get()) {
    return sortpfRatesByQuery(getPFRatesByQueryWithIcm.get());
  }

  const lmCheckListFilters = jobDetailStore.lmCheckListFilters;
  const filterData = getPFRatesByQueryWithIcm.get().filter((record) => {
    const segmentFilter =
      lmCheckListFilters.segment.length === 0
        ? true
        : lmCheckListFilters.segment.includes(record.segment);

    const queryFilter = record.query
      .toLowerCase()
      .includes(lmCheckListFilters.query.toLowerCase());

    const incompleteDataFilter = () => {
      if (lmCheckListFilters.incompleteDataOnly) {
        for (const key of LMCheckListSortKeys) {
          const pfRate = record[key];
          if (pfRate !== undefined && pfRate.missing > 0) {
            return true;
          }
        }
        return false;
      }
      return true;
    };

    const assertionsWithIcmOnlyFilter = lmCheckListFilters.assertionsWithIcmOnly
      ? record.icms !== undefined
      : true;
    const assertionsWithoutIcmOnlyFilter =
      lmCheckListFilters.assertionsWithoutIcmOnly
        ? record.icms === undefined
        : true;
    const raiContentFilter = lmCheckListFilters.raiContent
      ? record.segment !== harmfulRAISegment
      : true;

    return (
      segmentFilter &&
      queryFilter &&
      incompleteDataFilter() &&
      assertionsWithIcmOnlyFilter &&
      assertionsWithoutIcmOnlyFilter &&
      raiContentFilter
    );
  });
  return sortpfRatesByQuery(filterData);
});

export const getEmptyFilters = () => {
  const filters = jobDetailStore.lmCheckListFilters;
  if (filters === undefined) {
    return true;
  }

  const isEmptyStatusFilter =
    filters.assertionStatus.includes("") ||
    filters.assertionStatus.length === 0;

  const isEmptyLevelFilter =
    filters.assertionLevel.includes("") || filters.assertionLevel.length === 0;
  return (
    isEmptyStatusFilter &&
    isEmptyLevelFilter &&
    filters.assertionText === "" &&
    filters.assertionOwner === "" &&
    !filters.incompleteDataOnly &&
    !filters.showPartialFailures &&
    !filters.assertionsWithIcmOnly &&
    !filters.assertionsWithoutIcmOnly &&
    !filters.raiContent
  );
};

const assertionsWithIcm = computed((): LMChecklistAssertionWithIcm[] => {
  const assertions = jobDetailStore.lmchecklistAssertionsResponse ?? [];
  const icms = jobDetailStore.lmchecklistIcmsResponse;
  const icmMap = new Map<string, number>();
  if (icms !== undefined) {
    icms.forEach((icm) => {
      icmMap.set(`${icm.QueryHash}|${icm.Assertion}`, icm.IncidentId);
    });
  }
  return assertions.map((assertion) => {
    const icm = icmMap.get(`${assertion.queryHash}|${assertion.assertion}`);
    return { ...assertion, icm: icm };
  });
});

const sortAssertions = (filteredAssertions: LMChecklistAssertionWithIcm[]) => {
  const sorting = jobDetailStore.sorting;
  const sorted = [...filteredAssertions].sort(
    (a: LMChecklistAssertion, b: LMChecklistAssertion) => {
      let valA;
      let valB;
      if (sorting.field === "control" || sorting.field === "experiment") {
        valA = a.score[sorting.field];
        valB = b.score[sorting.field];
      } else {
        valA = a[sorting.field as keyof LMChecklistAssertion];
        valB = b[sorting.field as keyof LMChecklistAssertion];
      }

      if (valA === undefined) {
        return 1;
      } else if (valB === undefined) {
        return -1;
      }

      return (
        valA
          .toString()
          .trim()
          .toLowerCase()
          .localeCompare(valB.toString().trim().toLowerCase(), "en", {
            numeric: true,
          }) * (sorting.ascending ? 1 : -1)
      );
    },
  );
  return sorted;
};
export const getFilteredAssertions = computed(() => {
  const filters = jobDetailStore.lmCheckListFilters;
  if (getEmptyFilters()) {
    return sortAssertions(assertionsWithIcm.get());
  }

  const filterData = assertionsWithIcm.get().filter((assertion) => {
    const statusFilter = filters.assertionStatus.reduce((acc, status) => {
      switch (status) {
        case AssertionStatus.PIC:
          return acc && assertion.score.control === 2;
        case AssertionStatus.FIC:
          if (filters.showPartialFailures) {
            return (
              acc &&
              assertion.score.control !== undefined &&
              assertion.score.control < 2
            );
          } else {
            return (
              acc &&
              assertion.score.control !== undefined &&
              assertion.score.control === 0
            );
          }
        case AssertionStatus.PIT:
          return acc && assertion.score.experiment === 2;
        case AssertionStatus.FIT:
          if (filters.showPartialFailures) {
            return (
              acc &&
              assertion.score.experiment !== undefined &&
              assertion.score.experiment < 2
            );
          } else {
            return (
              acc &&
              assertion.score.experiment !== undefined &&
              assertion.score.experiment === 0
            );
          }
        default:
          return acc;
      }
    }, true);

    const levelFilter =
      filters.assertionLevel.includes("") || filters.assertionLevel.length === 0
        ? true
        : filters.assertionLevel
            .map((level) => level.toLowerCase())
            .includes(assertion.level);

    const textFilter = assertion.assertion
      .toLowerCase()
      .includes(filters.assertionText.toLowerCase());

    const ownerFilter =
      (filters.assertionOwner.trim() === "" ||
        assertion.owner
          ?.toLowerCase()
          ?.includes(filters.assertionOwner.toLowerCase())) ??
      false;

    const incompleteDataFilter = filters.incompleteDataOnly
      ? assertion.score.control === undefined ||
        assertion.score.experiment === undefined
      : true;

    let partialFailuresFilter;

    if (filters.showPartialFailures) {
      partialFailuresFilter = true;
    } else {
      if (
        filters.assertionStatus.includes(AssertionStatus.FIC) ||
        filters.assertionStatus.includes(AssertionStatus.FIT)
      ) {
        partialFailuresFilter =
          assertion.score.control === 0 || assertion.score.experiment === 0;
      } else {
        partialFailuresFilter = true;
      }
    }

    const assertionsWithIcmOnlyFilter = filters.assertionsWithIcmOnly
      ? assertion.icm !== undefined
      : true;
    const assertionsWithoutIcmOnlyFilter = filters.assertionsWithoutIcmOnly
      ? assertion.icm === undefined
      : true;
    const raiContentFilter = filters.raiContent
      ? assertion.segment !== harmfulRAISegment
      : true;

    return (
      statusFilter &&
      levelFilter &&
      textFilter &&
      ownerFilter &&
      incompleteDataFilter &&
      partialFailuresFilter &&
      assertionsWithIcmOnlyFilter &&
      assertionsWithoutIcmOnlyFilter &&
      raiContentFilter
    );
  });
  return sortAssertions(filterData);
});
