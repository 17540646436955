import {
  Body1,
  Button,
  Checkbox,
  Dropdown,
  Input,
  makeStyles,
  mergeClasses,
  Option,
  Switch,
} from "@fluentui/react-components";
import {
  ArrowLeft24Regular,
  ArrowReset20Regular,
  ArrowRight24Regular,
  CaretDown12Filled,
  CaretUp12Filled,
  ChevronLeftRegular,
  Filter24Regular,
  Search20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Tip } from "../../../../../components/Shared/Tip";
import { ConditionalWrapper } from "../../../../../components/Wrappers/ConditionalWrapper";
import { openSidePane } from "../../../../../mutators/sidePaneMutators";
import { getPFValue } from "../../../helpers/lmchecklistMetricsHelper";
import type { PassFailRate } from "../../../models/LMChecklistPassFailRates";
import {
  updatedIsDevUIDialogOpen,
  updatedLMCheckListFilters,
  updatedShowScores,
  updatedSorting,
} from "../../../mutators/jobDetailsMutators";
import {
  getEmptylmCheckListFilters,
  getFilteredpfRatesByQuery,
  getFilteredQueryData,
} from "../../../selectors/lmchecklist";
import type { LMChecklistDetailsAndPassFailRateByQueryWithIcm } from "../../../store/jobDetailStore";
import {
  defaultLMCheckListFilters,
  jobDetailStore,
  LMCheckListSortKeys,
} from "../../../store/jobDetailStore";
import { resultStore } from "../../../store/resultStore";
import {
  DevUIDialog,
  type IDevUIDialogKeyProps,
} from "../../Dialog/DevUIDialog";
import { AssertionDetails } from "../SidePane/AssertionDetails";
import { useSharedTableStyles } from "./shardTableStyles";

const useStyles = makeStyles({
  avgValueCell: {
    fontWeight: "600",
  },
  sorting: {
    display: "flex",
    flexDirection: "column",
  },
  caretUp: {
    marginBottom: "-12px",
  },
  caretDown: {
    marginBottom: "-4px",
  },
  criticalFailure: {
    backgroundColor: "rgba(221, 66, 66, 0.30)",
  },
  rowHover: {
    cursor: "pointer",
  },
});

const ITEMS_PER_PAGE = 20;

export const LMChecklistQueryTable = observer(() => {
  const sharedStyles = useSharedTableStyles();
  const styles = useStyles();

  const valueCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.valueCell,
    sharedStyles.rightCell,
  );

  const hasAccess = resultStore.resultJob?.Properties?.HasReadPermission;
  const icms = jobDetailStore.lmchecklistIcmsResponse;
  const details = jobDetailStore.lmchecklistDetailsResponse ?? [];

  const [devUIDialogProps, setDevUIDialogProps] = useState<
    IDevUIDialogKeyProps | undefined
  >(undefined);

  const metricsHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
  );

  const metricsSubHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
    sharedStyles.subTitleCell,
  );

  const avgValueCell = mergeClasses(styles.avgValueCell, valueCell);

  const rowStyle = mergeClasses(sharedStyles.rowStyle, styles.rowHover);

  const { showScores, sorting, lmCheckListFilters, isDevUIDialogOpen } =
    jobDetailStore;

  const emptylmCheckListFilters = getEmptylmCheckListFilters.get();
  const queryData = getFilteredQueryData.get();
  const pfRatesByQuery = getFilteredpfRatesByQuery.get();

  const [page, setPage] = useState<number>(1);

  const numPages = Math.ceil(queryData.length / ITEMS_PER_PAGE);

  const start = (page - 1) * ITEMS_PER_PAGE;
  const end = page * ITEMS_PER_PAGE;
  const slicedData = () => {
    return queryData.slice(start, end);
  };

  const slicedpfRatesByQuery = () => {
    return pfRatesByQuery.slice(start, end);
  };

  const renderPagination = () => (
    <div className={sharedStyles.filtersGroup}>
      <Button
        onClick={() => {
          setPage(page - 1);
        }}
        disabled={page === 1}
        appearance="subtle"
        icon={<ArrowLeft24Regular />}
      />
      <span>{`${Math.min(page, numPages)} of ${numPages}`}</span>
      <Button
        onClick={() => {
          setPage(page + 1);
        }}
        disabled={page === numPages || numPages === 0}
        appearance="subtle"
        icon={<ArrowRight24Regular />}
      />
    </div>
  );

  const getScoreValue = (score: number | undefined) =>
    score !== undefined ? score.toFixed(2) : "";

  const getScoreAvgValue = (scoreAvg: number) =>
    scoreAvg >= 0 ? scoreAvg.toFixed(2) : "";

  const getPFToolTip = (pfRate: PassFailRate | undefined) => {
    if (pfRate !== undefined) {
      if (pfRate.missing > 0 && pfRate.missing >= pfRate.total) {
        return `${pfRate.missing} assertions missing from result.`;
      }
      return `${pfRate.passed} of ${
        pfRate.total - pfRate.missing
      } assertions passed. ${pfRate.missing} assertions missing from result.`;
    }

    return "No assertions.";
  };

  const getCriticalCell = (score?: number, pfRate?: PassFailRate) => {
    if (score !== undefined) {
      return score < 2
        ? mergeClasses(valueCell, styles.criticalFailure)
        : valueCell;
    } else if (pfRate !== undefined) {
      return pfRate.passed < pfRate.total
        ? mergeClasses(valueCell, styles.criticalFailure)
        : valueCell;
    }
  };

  const handleSegmentFilterChange = (segment: string) => {
    let currentSegments = [...lmCheckListFilters.segment];
    if (lmCheckListFilters.segment.includes(segment)) {
      currentSegments = currentSegments.filter((item) => item !== segment);
    } else {
      currentSegments.push(segment);
    }
    updatedLMCheckListFilters({
      ...lmCheckListFilters,
      segment: currentSegments,
    });
  };

  const segments = () => {
    const segmentsSet = new Set<string>();
    details.forEach((record) => {
      segmentsSet.add(record.segment);
    });
    return Array.from(segmentsSet).sort((a, b) => a.localeCompare(b));
  };

  const segmentFilterValue = (segmentsList: string[]) =>
    segmentsList.map((segment) => segment.split(" - ")[0]).join(", ");

  const renderFilterBar = () => {
    return (
      <div className={sharedStyles.filterBar}>
        <div className={sharedStyles.filtersGroup}>
          <Filter24Regular className={sharedStyles.filterIcon} />
          <Dropdown
            className={sharedStyles.filterBarItem}
            multiselect={true}
            placeholder="Segment"
            data-testid="segment-dropdown"
            positioning={"below"}
            value={segmentFilterValue(lmCheckListFilters.segment)}
            selectedOptions={lmCheckListFilters.segment}
            onOptionSelect={(_, option) =>
              handleSegmentFilterChange(option.optionValue as string)
            }
          >
            {segments().map((segment) => (
              <Option key={segment} value={segment} data-testid={segment}>
                {segment}
              </Option>
            ))}
          </Dropdown>
          <Input
            className={sharedStyles.filterBarItem}
            placeholder="Search queries"
            aria-label="Search queries"
            value={lmCheckListFilters.query}
            contentBefore={<Search20Regular />}
            onChange={(_, data) =>
              updatedLMCheckListFilters({
                ...lmCheckListFilters,
                query: data.value,
              })
            }
          />
          {!emptylmCheckListFilters && (
            <Button
              className={sharedStyles.reset}
              onClick={() => {
                updatedLMCheckListFilters(defaultLMCheckListFilters);
              }}
            >
              <ArrowReset20Regular className={sharedStyles.resetIcon} />
              <span>Reset</span>
            </Button>
          )}
        </div>
        <div className={sharedStyles.filtersGroup}>
          <ConditionalWrapper
            condition={icms === undefined}
            Wrapper={Tip}
            wrapperProps={{
              content: "Filter disabled due to failure to query IcMs.",
              relationship: "label",
              withArrow: true,
            }}
          >
            <Checkbox
              label="Assertions with IcM only"
              checked={lmCheckListFilters.assertionsWithIcmOnly}
              disabled={icms === undefined}
              onChange={() => {
                setPage(1);
                updatedLMCheckListFilters({
                  ...lmCheckListFilters,
                  assertionsWithIcmOnly:
                    !lmCheckListFilters.assertionsWithIcmOnly,
                  assertionsWithoutIcmOnly: false,
                  raiContent: true,
                });
              }}
            />
          </ConditionalWrapper>
          <ConditionalWrapper
            condition={icms === undefined}
            Wrapper={Tip}
            wrapperProps={{
              content: "Filter disabled due to failure to query IcMs.",
              relationship: "label",
              withArrow: true,
            }}
          >
            <Checkbox
              label="Assertions without IcM only"
              checked={lmCheckListFilters.assertionsWithoutIcmOnly}
              disabled={icms === undefined}
              onChange={() => {
                setPage(1);
                updatedLMCheckListFilters({
                  ...lmCheckListFilters,
                  assertionsWithIcmOnly: false,
                  assertionsWithoutIcmOnly:
                    !lmCheckListFilters.assertionsWithoutIcmOnly,
                });
              }}
            />
          </ConditionalWrapper>
          <Checkbox
            label="Incomplete data only"
            checked={lmCheckListFilters.incompleteDataOnly}
            onChange={() => {
              updatedLMCheckListFilters({
                ...lmCheckListFilters,
                incompleteDataOnly: !lmCheckListFilters.incompleteDataOnly,
              });
            }}
          />
          <Switch
            checked={showScores}
            onChange={() => updatedShowScores(!showScores)}
            label="Scores"
            data-testid="scores-switch"
          />
          <div>
            <Checkbox
              checked={!lmCheckListFilters.raiContent}
              onChange={() => {
                setPage(1);
                updatedLMCheckListFilters({
                  ...lmCheckListFilters,
                  raiContent: !lmCheckListFilters.raiContent,
                });
              }}
            />
            <span style={{ color: "dimgray", fontSize: "14px" }}>
              &nbsp;Include Sensitive RAI Queries (
              <a
                href="https://microsoft.sharepoint-df.com/:w:/t/CI_RAI/EXqNzIO-P6BNgR4QUshk8NkBPTERl-SHHnOGwSiftoZAbQ?e=zxfV9C"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disclaimer
              </a>
              )
            </span>
          </div>
          {renderPagination()}
        </div>
      </div>
    );
  };

  const getAvgScores = () => {
    const avgs = queryData.reduce(
      (acc, item) => {
        for (const key of LMCheckListSortKeys) {
          const score = item[key];
          if (score !== undefined) {
            acc[key].sum += score;
            acc[key].count += 1;
          }
        }

        return acc;
      },
      {
        criticalControl: { sum: 0, count: 0 },
        criticalExperiment: { sum: 0, count: 0 },
        expectedControl: { sum: 0, count: 0 },
        expectedExperiment: { sum: 0, count: 0 },
        aspirationalControl: { sum: 0, count: 0 },
        aspirationalExperiment: { sum: 0, count: 0 },
        tControl: { sum: 0, count: 0 },
        tExperiment: { sum: 0, count: 0 },
      },
    );

    return avgs;
  };

  const getTotalpfRatesByQuery = () => {
    const totals = pfRatesByQuery.reduce(
      (acc, item) => {
        for (const key of LMCheckListSortKeys) {
          const pfRate = item[key];
          if (pfRate) {
            acc[key].passed += pfRate.passed;
            acc[key].total += pfRate.total;
            acc[key].missing += pfRate.missing;
          }
        }

        return acc;
      },
      {
        criticalControl: { passed: 0, total: 0, missing: 0 },
        criticalExperiment: { passed: 0, total: 0, missing: 0 },
        expectedControl: { passed: 0, total: 0, missing: 0 },
        expectedExperiment: { passed: 0, total: 0, missing: 0 },
        aspirationalControl: { passed: 0, total: 0, missing: 0 },
        aspirationalExperiment: { passed: 0, total: 0, missing: 0 },
        tControl: { passed: 0, total: 0, missing: 0 },
        tExperiment: { passed: 0, total: 0, missing: 0 },
      },
    );

    return totals;
  };

  const sortIconVisibility = (sortedField: string, sortDirection: boolean) =>
    (sortedField === sorting.field && sortDirection) ||
    sortedField !== sorting.field
      ? "visible"
      : "hidden";

  const getSortingIcon = (sortedField: string) => (
    <div className={styles.sorting}>
      <div
        className={styles.caretUp}
        style={{
          visibility: sortIconVisibility(sortedField, sorting.ascending),
        }}
      >
        <CaretUp12Filled />
      </div>
      <div
        className={styles.caretDown}
        style={{
          visibility: sortIconVisibility(sortedField, !sorting.ascending),
        }}
      >
        <CaretDown12Filled />
      </div>
    </div>
  );

  const handleSortingChange = (sortedField: string) => {
    if (sortedField === sorting.field) {
      updatedSorting({ field: sortedField, ascending: !sorting.ascending });
    } else {
      updatedSorting({ field: sortedField, ascending: sorting.ascending });
    }
  };

  const renderTableHeader = () => (
    <thead className={sharedStyles.thead}>
      <tr className={sharedStyles.rowStyle} key="main-cols">
        <th
          className={metricsHeaderCell}
          rowSpan={2}
          onClick={() => handleSortingChange("query")}
        >
          <div className={sharedStyles.sortHeader}>
            Utterance
            {getSortingIcon("query")}
          </div>
        </th>
        <th
          className={metricsHeaderCell}
          rowSpan={2}
          onClick={() => handleSortingChange("segment")}
        >
          <div className={sharedStyles.sortHeader} data-testid="segment-header">
            Segment
            {getSortingIcon("segment")}
          </div>
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          Critical
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          Expected
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          Aspirational
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          {showScores ? "Score" : "Overall Pass Rate"}
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          DevUI links
        </th>
      </tr>
      <tr className={sharedStyles.rowStyle} key="c-t-cols">
        {LMCheckListSortKeys.map((key) => (
          <th
            key={`${key}-header`}
            className={metricsSubHeaderCell}
            onClick={() => handleSortingChange(key)}
          >
            <div className={sharedStyles.sortHeader}>
              {key.includes("Control") ? "Control" : "Treatment"}
              {getSortingIcon(key)}
            </div>
          </th>
        ))}
        <th className={metricsSubHeaderCell}>Control</th>
        <th className={metricsSubHeaderCell}>Treatment</th>
      </tr>
    </thead>
  );

  const renderAssertionDetails = (
    record: LMChecklistDetailsAndPassFailRateByQueryWithIcm,
  ) => {
    const assertions =
      jobDetailStore.lmchecklistAssertionsResponse?.filter(
        (a) => a.queryHash === record.queryHash,
      ) ?? [];
    return (
      <AssertionDetails
        JobId={resultStore.resultJob?.JobId ?? ""}
        query={record.query}
        queryHash={record.queryHash}
        assertions={assertions}
        sydneyReply={record.sydneyReply}
        icms={record.icms}
      />
    );
  };

  const renderScoreTableBody = () => (
    <tbody className={sharedStyles.tbody}>
      {slicedData().map((record) => {
        return (
          <tr
            key={`${record.queryHash}-scores`}
            className={rowStyle}
            onClick={() => openSidePane(() => renderAssertionDetails(record))}
          >
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <div
                  className={sharedStyles.expand}
                  onClick={(event) => {
                    openSidePane(() => renderAssertionDetails(record));
                    event?.stopPropagation();
                  }}
                >
                  <ChevronLeftRegular />
                </div>
                <Tip withArrow content={record.query} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {record.query}
                  </Body1>
                </Tip>
              </div>
            </td>
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <Tip withArrow content={record.segment} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {record.segment}
                  </Body1>
                </Tip>
              </div>
            </td>
            {LMCheckListSortKeys.map((key) => {
              const score = record[key];
              return key.includes("critical") ? (
                <td
                  className={getCriticalCell(score)}
                  key={`${record.queryHash}-${key}-scores`}
                >
                  {getScoreValue(score)}
                </td>
              ) : (
                <td className={valueCell} key={`${record.queryHash}-${key}`}>
                  {getScoreValue(score)}
                </td>
              );
            })}
            <td className={valueCell}>
              {hasAccess ? (
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    updatedIsDevUIDialogOpen(true);
                    setDevUIDialogProps({
                      query: record.query,
                      queryHash: record.queryHash,
                      jobId: resultStore.resultJob?.JobId ?? "",
                      exp_name: "control",
                    });
                    event.stopPropagation();
                  }}
                >
                  Control link
                </a>
              ) : (
                "No Access"
              )}
            </td>
            <td className={valueCell}>
              {hasAccess ? (
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    updatedIsDevUIDialogOpen(true);
                    setDevUIDialogProps({
                      query: record.query,
                      queryHash: record.queryHash,
                      jobId: resultStore.resultJob?.JobId ?? "",
                      exp_name: "experiment",
                    });
                    event.stopPropagation();
                  }}
                >
                  Treatment link
                </a>
              ) : (
                "No Access"
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  const renderPFTableBody = () => (
    <tbody className={sharedStyles.tbody}>
      {slicedpfRatesByQuery().map((record) => {
        return (
          <tr
            key={`${record.queryHash}-pfRatesByQuery`}
            className={rowStyle}
            onClick={() => openSidePane(() => renderAssertionDetails(record))}
          >
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <div
                  className={sharedStyles.expand}
                  onClick={(event) => {
                    openSidePane(() => renderAssertionDetails(record));
                    event?.stopPropagation();
                  }}
                >
                  <ChevronLeftRegular />
                </div>
                <Tip withArrow content={record.query} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {record.query}
                  </Body1>
                </Tip>
              </div>
            </td>
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <Tip withArrow content={record.segment} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {record.segment}
                  </Body1>
                </Tip>
              </div>
            </td>
            {LMCheckListSortKeys.map((key) => {
              const pfRate = record[key];
              return key.includes("critical") ? (
                <td
                  className={getCriticalCell(undefined, pfRate)}
                  key={`${record.queryHash}-${key}-pfRatesByQuery`}
                >
                  <Tip
                    withArrow
                    content={getPFToolTip(pfRate)}
                    relationship="label"
                  >
                    <Body1>{getPFValue(pfRate)}</Body1>
                  </Tip>
                </td>
              ) : (
                <td
                  className={valueCell}
                  key={`${record.queryHash}-${key}-pfRatesByQuery`}
                >
                  <Tip
                    withArrow
                    content={getPFToolTip(pfRate)}
                    relationship="label"
                  >
                    <Body1>{getPFValue(pfRate)}</Body1>
                  </Tip>
                </td>
              );
            })}
            <td className={valueCell}>
              {hasAccess ? (
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    updatedIsDevUIDialogOpen(true);
                    setDevUIDialogProps({
                      query: record.query,
                      queryHash: record.queryHash,
                      jobId: resultStore.resultJob?.JobId ?? "",
                      exp_name: "control",
                    });
                    event.stopPropagation();
                  }}
                >
                  Control link
                </a>
              ) : (
                "No Access"
              )}
            </td>
            <td className={valueCell}>
              {hasAccess ? (
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    updatedIsDevUIDialogOpen(true);
                    setDevUIDialogProps({
                      query: record.query,
                      queryHash: record.queryHash,
                      jobId: resultStore.resultJob?.JobId ?? "",
                      exp_name: "experiment",
                    });
                    event.stopPropagation();
                  }}
                >
                  Treatment link
                </a>
              ) : (
                "No Access"
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  const renderScoreTableFooter = () => {
    const avgs = getAvgScores();
    return (
      <tfoot>
        <tr className={sharedStyles.rowStyle} key="score-footer">
          <td className={avgValueCell} colSpan={2}>
            Averages:
          </td>
          {LMCheckListSortKeys.map((key) => {
            const avgObj = avgs[key];
            const avg = avgObj.sum / avgObj.count;
            return (
              <td key={`${key}-scores-footer`} className={avgValueCell}>
                {getScoreAvgValue(avg)}
              </td>
            );
          })}
          <td className={avgValueCell} colSpan={2}>
            Total {emptylmCheckListFilters ? "" : "filtered"} queries:{" "}
            {queryData.length}
          </td>
        </tr>
      </tfoot>
    );
  };

  const renderPFTableFooter = () => {
    const totals = getTotalpfRatesByQuery();
    return (
      <tfoot>
        <tr className={sharedStyles.rowStyle} key="pf-footer">
          <td className={avgValueCell} colSpan={2}>
            Totals:
          </td>
          {LMCheckListSortKeys.map((key, index) => (
            <td key={`${key}-pfRatesByQuery-footer`} className={avgValueCell}>
              <Tip
                key={index}
                withArrow
                content={getPFToolTip(totals[key])}
                relationship="label"
              >
                <Body1 className={styles.avgValueCell}>
                  {getPFValue(totals[key])}
                </Body1>
              </Tip>
            </td>
          ))}
          <td className={avgValueCell} colSpan={2}>
            Total {emptylmCheckListFilters ? "" : "filtered"} queries:{" "}
            {pfRatesByQuery.length}
          </td>
        </tr>
      </tfoot>
    );
  };

  return (
    <>
      {renderFilterBar()}
      <table className={sharedStyles.table}>
        {renderTableHeader()}
        {showScores ? (
          <>
            {renderScoreTableBody()}
            {renderScoreTableFooter()}
          </>
        ) : (
          <>
            {renderPFTableBody()}
            {renderPFTableFooter()}
          </>
        )}
      </table>
      <DevUIDialog
        key={JSON.stringify(devUIDialogProps)}
        isOpen={isDevUIDialogOpen}
        content={devUIDialogProps}
        onComplete={() => updatedIsDevUIDialogOpen(false)}
      />
    </>
  );
});
