import { checkUrlValidation } from "../../../helpers/formatHelper";
import { jobStore } from "../../../store/jobStore";

const bypassUrlCheckTemplateType = ["SearchEval"];
export const checkUrl = (value: string | undefined, path: string) => {
  if (value === undefined) {
    if (
      bypassUrlCheckTemplateType.includes(jobStore.selectedTemplate?.Type ?? "")
    ) {
      return undefined;
    }
    return { path, message: "Sydney url should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Sydney url should not be empty!" };
  }

  if (!checkUrlValidation(value ?? "")) {
    return { path, message: "Sydney url is invalid!" };
  }
  return undefined;
};

export const checkAugloopServiceUrl = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return { path, message: "Augloop url should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Augloop url should not be empty!" };
  }

  if (!checkUrlValidation(value ?? "")) {
    return {
      path,
      message: "Augloop url is invalid! We only support wss url for now.",
    };
  }
  return undefined;
};
