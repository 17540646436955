import { mergeClasses } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import type { JobGroupData } from "sydneyeval-shared";
import { uniqByObject } from "sydneyeval-shared";
import { ExpandIcon } from "../../../../components/Shared/Icons";
import { getGroupList } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import { Job } from "../../models/Job";
import {
  updateExpandedIndex,
  updateGroupList,
} from "../../mutators/jobListMutators";
import { jobListStore } from "../../store/jobListStore";
import { useGroupTableStyles } from "./groupTableStyles";
import { JobNameCell } from "./JobCell/JobNameCell";
import { JobStatusCell } from "./JobCell/JobStatusCell";

export const GroupsTable = observer(() => {
  const sharedStyles = useGroupTableStyles();

  const leftCell = mergeClasses(sharedStyles.cell, sharedStyles.leftCell);
  const inforCell = mergeClasses(sharedStyles.cell, sharedStyles.inforCell);

  const getExpandStyle = (index: number) =>
    jobListStore.groupExpandIndex === index
      ? mergeClasses(sharedStyles.expand, sharedStyles.expanded)
      : sharedStyles.expand;
  const groups = jobListStore.groups;

  React.useEffect(() => {
    perfWrapper(
      "LoadNextJobList",
      getGroupList({}).then((fetchedData) => {
        groups.push(...fetchedData);
        updateGroupList(uniqByObject(groups));
      }),
    );
  }, []);

  const handleExpand = (index: number) => {
    if (jobListStore.groupExpandIndex === index) {
      updateExpandedIndex(undefined);
      return;
    }
    updateExpandedIndex(index);
  };

  const jobsOfGroup = (group: JobGroupData) => {
    if (group.Jobs) {
      return group.Jobs.map((job) => Job(job, "Job"));
    }
  };

  const renderGroupList = () => {
    return (
      <table className={sharedStyles.table}>
        <thead className={sharedStyles.thead}>
          <tr className={sharedStyles.rowStyle}>
            <th className={leftCell}>Group Name</th>
            <th className={inforCell}>Creator</th>
            <th className={inforCell}>Created At</th>
          </tr>
        </thead>
        <tbody className={sharedStyles.tbody}>
          {groups.map((group) => {
            const subJobs = jobsOfGroup(group);
            return (
              <React.Fragment key={group.ID}>
                <tr className={sharedStyles.rowStyle}>
                  <td className={sharedStyles.flexCell}>
                    {group.Name}
                    <div
                      className={getExpandStyle(group.ID)}
                      data-testid={"expand-icon-" + group.ID}
                      onClick={(event) => {
                        handleExpand(group.ID);
                        event?.stopPropagation();
                      }}
                    >
                      <ExpandIcon />
                    </div>
                  </td>
                  <td>{group.CreatedBy}</td>
                  <td>{group.CreatedAt}</td>
                </tr>

                {group.ID === jobListStore.groupExpandIndex && (
                  <tr className={sharedStyles.rowStyle}>
                    <td colSpan={3}>
                      <table className={sharedStyles.table}>
                        <thead className={sharedStyles.thead}>
                          <tr className={sharedStyles.rowStyle}>
                            <th className={leftCell}>Job Name</th>
                            <th className={inforCell}>Experiment Name</th>
                            <th className={inforCell}>Status</th>
                          </tr>
                        </thead>
                        <tbody className={sharedStyles.tbody}>
                          {subJobs?.map((job) => (
                            <tr key={job.ID} className={sharedStyles.rowStyle}>
                              <td className={leftCell}>
                                <JobNameCell job={job} />
                              </td>
                              <td className={leftCell}>{job.ExperimentName}</td>
                              <td className={leftCell}>
                                <JobStatusCell job={job} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };
  return <>{renderGroupList()}</>;
});
