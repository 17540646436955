import { shorthands, tokens } from "@fluentui/react-components";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";

export const useCreationStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithMediumGap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      ...shorthands.gap("32px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    spinner: {
      width: "100%",
    },
    metricsContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    checkboxContainer: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("12px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    checkbox: {
      '> input[type="checkbox"]:checked': {
        backgroundColor: "red",
        color: "red",
      },
    },
    jsonPreviewer: {
      height: "600px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    dataSetsPreviewer: {
      height: "100px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    divider: {
      width: "100%",
      height: "1px",
      backgroundColor: "#D2D0CE",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
    headerContainer: {
      ...shorthands.margin("50px", "0", "28px", "0"),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerTitleContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    headerTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    listbox: {
      maxHeight: "300px",
    },
    textBoxHeight: {
      height: "100px",
    },
    warningBox: {
      color: tokens.colorStatusWarningBorderActive,
    },
    warningIcon: {
      verticalAlign: "middle",
    },
    operationButton: {
      display: "flex",
      ...shorthands.margin("50px", "0", "28px", "0"),
      flexDirection: "row-reverse",
      gap: "8px",
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);
