import {
  Body1,
  Button,
  Checkbox,
  Spinner,
  mergeClasses,
} from "@fluentui/react-components";
import {
  Archive24Filled,
  ArrowUndo24Filled,
  DraftsRegular,
  Edit24Filled,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { Fragment, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ExpandIcon } from "../../../../../components/Shared/Icons";
import { Tip } from "../../../../../components/Shared/Tip";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import { useSharedTableStyles } from "../../ResultsComparison/Table/shardTableStyles";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type { AssertionProperty, Query } from "../types/Query";
import { QueryManagementExpanded } from "./QueryManagementExpanded";

type QueryManagementAction =
  | {
      type: "add" | "delete";
      query: Query;
    }
  | {
      type: "empty";
    };

type QueryManagementQueryTableProps = {
  queries: Query[];
  setQueryToEdit: React.Dispatch<React.SetStateAction<Query | undefined>>;
  setQueryDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedQueries: Set<Query>;
  dispatch: React.Dispatch<QueryManagementAction>;
  isLoading: boolean;
  handleSelectAllClick: (allFilteredChecked: boolean) => void;
  handleArchive: (id: string) => void;
  handleRestore: (id: string) => void;
};

export const QueryManagementQueryTable = observer(
  (props: QueryManagementQueryTableProps) => {
    const {
      queries,
      setQueryToEdit,
      setQueryDialogOpen,
      checkedQueries,
      dispatch,
      isLoading,
      handleSelectAllClick,
      handleArchive,
      handleRestore,
    } = props;

    const styles = useQueryManagementStyles();
    const sharedTableStyles = useSharedTableStyles();

    const [expandedQuery, setExpandedQuery] = useState<string | undefined>(
      undefined,
    );

    const recordsPerPage = 30;
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [records, setRecords] = useState<number>(recordsPerPage);
    useEffect(() => {
      setRecords(Math.min(recordsPerPage, queries.length));
      setHasMore(queries.length > recordsPerPage);
    }, [queries.length]);
    const loadMore = useCallback(() => {
      if (records >= queries.length) {
        setHasMore(false);
      } else {
        setRecords(records + recordsPerPage);
      }
    }, [records, queries.length]);

    const columnProps = {
      Query: { minWidth: "300px", weight: 3 },
      "Segment 1": { minWidth: "200px", weight: 2 },
      "Segment 2": { minWidth: "200px", weight: 2 },
      "Query Owner": { minWidth: "150px", weight: 1 },
      Environment: { minWidth: "120px", weight: 1 },
      Term: { minWidth: "150px", weight: 1 },
      "Grounding Data Source": { minWidth: "180px", weight: 1 },
      "FRE or Sparkle Prompt": { minWidth: "180px", weight: 1 },
      "Data Source Info": { minWidth: "220px", weight: 2 },
      Timestamp: { minWidth: "200px", weight: 1 },
      "User ID": { minWidth: "150px", weight: 1 },
      Sets: { minWidth: "200px", weight: 2 },
      Flags: { minWidth: "200px", weight: 2 },
    };

    const headerCell = mergeClasses(styles.th, styles.cell);
    const getColumnStyle = (minWidth: string, weight: number) => {
      return {
        flexGrow: weight,
        flexShrink: weight,
        flexBasis: 0,
        minWidth: minWidth,
        width: "100%",
      };
    };

    const getTableRowStyle = (query: Query) => {
      if (query.archived) {
        return mergeClasses(styles.bodyRow, styles.archived);
      }

      if (checkedQueries.has(query)) {
        return mergeClasses(styles.bodyRow, styles.selected);
      }

      return styles.bodyRow;
    };

    const bodyCell = mergeClasses(sharedTableStyles.cell, styles.cell);
    const longTextNoMargin = mergeClasses(
      sharedTableStyles.longText,
      styles.noMarginLeft,
    );

    const handleExpand = (id: string) => {
      if (expandedQuery === id) {
        setExpandedQuery(undefined);
        return;
      }
      setExpandedQuery(id);
    };

    const getExpandStyle = (id: string) =>
      expandedQuery === id
        ? mergeClasses(sharedTableStyles.expand, sharedTableStyles.expanded)
        : sharedTableStyles.expand;

    const renderDraftIcon = () => (
      <Tip content="Draft" relationship="label">
        <DraftsRegular style={{ marginLeft: "12px" }} />
      </Tip>
    );

    const renderRestoreButton = (id: string) => (
      <Tip withArrow content="Restore" relationship="label">
        <Button
          aria-label="Restore Query"
          className={styles.queryAction}
          appearance="transparent"
          icon={<ArrowUndo24Filled />}
          onClick={(event) => {
            handleRestore(id);
            event?.stopPropagation();
            telemetryHelper.logUserActionEvent(
              "QueryManagementToolQueryRestored",
            );
          }}
        />
      </Tip>
    );

    const renderEditButton = (query: Query) => (
      <Tip withArrow content="Edit" relationship="label">
        <Button
          aria-label="Edit Query"
          className={styles.queryAction}
          appearance="transparent"
          icon={<Edit24Filled />}
          onClick={(event) => {
            setQueryToEdit(query);
            setQueryDialogOpen(true);
            event?.stopPropagation();
          }}
        />
      </Tip>
    );

    const renderArchiveButton = (id: string) => (
      <Tip withArrow content="Archive" relationship="label">
        <Button
          aria-label="Archive Query"
          className={styles.queryAction}
          appearance="transparent"
          icon={<Archive24Filled />}
          onClick={(event) => {
            handleArchive(id);
            event?.stopPropagation();
            telemetryHelper.logUserActionEvent(
              "QueryManagementToolQueryArchived",
            );
          }}
        />
      </Tip>
    );

    const renderTagLikeObjects = (
      objects: string[] | AssertionProperty[] | undefined,
    ) => (
      <div>
        {objects?.map((tag, index) => (
          <Tip key={index} content={tag} relationship="label">
            <div className={styles.tag}>{tag}</div>
          </Tip>
        ))}
      </div>
    );

    const renderTableHeader = () => {
      const allFilteredChecked =
        checkedQueries.size > 0 &&
        queries.every((query) => checkedQueries.has(query));

      return (
        <thead
          className={mergeClasses(sharedTableStyles.thead, styles.headerColor)}
        >
          <tr className={sharedTableStyles.rowStyle}>
            <th className={headerCell} style={getColumnStyle("24px", 1)}>
              <Checkbox
                checked={
                  allFilteredChecked
                    ? true
                    : checkedQueries.size === 0
                    ? false
                    : "mixed"
                }
                onClick={() => handleSelectAllClick(allFilteredChecked)}
              />
            </th>
            {Object.entries(columnProps).map(
              ([name, { minWidth, weight }], index) => (
                <th
                  key={index}
                  className={headerCell}
                  style={getColumnStyle(minWidth, weight)}
                >
                  <div>{name}</div>
                </th>
              ),
            )}
          </tr>
        </thead>
      );
    };

    const renderTableBody = () => {
      const queriesToDisplay = queries.slice(0, records);
      return (
        <tbody>
          {queriesToDisplay.map((query) => (
            <Fragment key={query.id}>
              <tr
                className={getTableRowStyle(query)}
                onClick={() => {
                  handleExpand(query.id);
                }}
              >
                <td className={bodyCell} style={getColumnStyle("24px", 1)}>
                  <Checkbox
                    checked={checkedQueries.has(query)}
                    onClick={(event) => {
                      if (checkedQueries.has(query)) {
                        dispatch({ type: "delete", query: query });
                      } else {
                        dispatch({ type: "add", query: query });
                      }
                      event.stopPropagation();
                    }}
                    disabled={query.archived}
                  />
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Query.minWidth,
                    columnProps.Query.weight,
                  )}
                >
                  <div
                    className={mergeClasses(
                      styles.query,
                      sharedTableStyles.longTextWrapper,
                    )}
                  >
                    <div
                      className={getExpandStyle(query.id)}
                      onClick={(event) => {
                        handleExpand(query.id);
                        event?.stopPropagation();
                      }}
                    >
                      <ExpandIcon />
                    </div>
                    {query.draft && renderDraftIcon()}
                    <Tip withArrow content={query.query} relationship="label">
                      <Body1 className={sharedTableStyles.longText}>
                        {query.query}
                      </Body1>
                    </Tip>
                    {query.archived ? (
                      <div>{renderRestoreButton(query.id)}</div>
                    ) : (
                      <div>
                        {renderEditButton(query)}
                        {renderArchiveButton(query.id)}
                      </div>
                    )}
                  </div>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["Segment 1"].minWidth,
                    columnProps["Segment 1"].weight,
                  )}
                >
                  <div className={sharedTableStyles.longTextWrapper}>
                    <Tip
                      withArrow
                      content={query.segmentOne ?? ""}
                      relationship="label"
                    >
                      <Body1 className={longTextNoMargin}>
                        {query.segmentOne}
                      </Body1>
                    </Tip>
                  </div>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["Segment 2"].minWidth,
                    columnProps["Segment 2"].weight,
                  )}
                >
                  <div className={sharedTableStyles.longTextWrapper}>
                    <Tip
                      withArrow
                      content={query.segmentTwo ?? ""}
                      relationship="label"
                    >
                      <Body1 className={longTextNoMargin}>
                        {query.segmentTwo}
                      </Body1>
                    </Tip>
                  </div>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["Query Owner"].minWidth,
                    columnProps["Query Owner"].weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.queryOwner ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.queryOwner}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Environment.minWidth,
                    columnProps.Environment.weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.environment ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.environment}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Term.minWidth,
                    columnProps.Term.weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.term ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.term}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["Grounding Data Source"].minWidth,
                    columnProps["Grounding Data Source"].weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.groundingDataSource ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.groundingDataSource}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["FRE or Sparkle Prompt"].minWidth,
                    columnProps["FRE or Sparkle Prompt"].weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.freOrSparklePrompt ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.freOrSparklePrompt}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["Data Source Info"].minWidth,
                    columnProps["Data Source Info"].weight,
                  )}
                >
                  <div className={sharedTableStyles.longTextWrapper}>
                    <Tip
                      withArrow
                      content={query.dataSourceInfo ?? ""}
                      relationship="label"
                    >
                      <Body1 className={longTextNoMargin}>
                        {query.dataSourceInfo}
                      </Body1>
                    </Tip>
                  </div>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Timestamp.minWidth,
                    columnProps.Timestamp.weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.timestamp ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.timestamp}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps["User ID"].minWidth,
                    columnProps["User ID"].weight,
                  )}
                >
                  <Tip
                    withArrow
                    content={query.userId ?? ""}
                    relationship="label"
                  >
                    <Body1>{query.userId}</Body1>
                  </Tip>
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Sets.minWidth,
                    columnProps.Sets.weight,
                  )}
                >
                  {query.sets && <div>{renderTagLikeObjects(query.sets)}</div>}
                </td>
                <td
                  className={bodyCell}
                  style={getColumnStyle(
                    columnProps.Flags.minWidth,
                    columnProps.Flags.weight,
                  )}
                >
                  {query.flags && (
                    <div>{renderTagLikeObjects(query.flags)}</div>
                  )}
                </td>
              </tr>
              {query.id === expandedQuery && (
                <tr
                  className={styles.assertions}
                  key={`${query.id}-assertions`}
                >
                  <td
                    className={bodyCell}
                    colSpan={Object.keys(columnProps).length + 1}
                  >
                    <QueryManagementExpanded
                      query={query}
                      renderTagLikeObjects={renderTagLikeObjects}
                      renderDraftIcon={renderDraftIcon}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      );
    };

    const renderQueriesTable = () => {
      if (isLoading) {
        return (
          <div className={styles.importView}>
            <Spinner />
          </div>
        );
      }

      return queries.length > 0 ? (
        <div
          data-testid="queryManagementQueryTable"
          className={styles.tableContainer}
        >
          <InfiniteScroll
            className={styles.scrollContainer}
            scrollThreshold={"50px"}
            dataLength={Math.min(records, queries.length)}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <div className={styles.spinner}>
                <Spinner />
              </div>
            }
            scrollableTarget={"mainViewScrollContainer"}
          >
            <table className={styles.table}>
              {renderTableHeader()}
              {renderTableBody()}
            </table>
          </InfiniteScroll>
        </div>
      ) : (
        <Body1 className={styles.noQueries}>No queries found.</Body1>
      );
    };

    return renderQueriesTable();
  },
);
