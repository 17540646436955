import { orchestrator } from "satcheljs";
import type {
  CreateGroupJobRouterParser,
  CreateJobRouterRequestData,
  JobOfGroup,
} from "sydneyeval-shared";
import { JobGroupSettings, parseJsonStrOptional } from "sydneyeval-shared";
import { createJobGroup } from "../../../helpers/apiHelper";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import { updateCurrentPath } from "../../../mutators/updateContributions";
import {
  addEditSubJobAction,
  createJobGroupAction,
  deleteSubJobAction,
  saveSubJobAction,
  submitJobGroupAction,
  updateSelectedGroupTemplatesAction,
} from "../actions/jobGroupActions";
import { generateFormattedDateString } from "../helpers/formatHelper";
import {
  resetGroupCreationStore,
  updateCreationMode,
  updateCurrentLocalJobId,
  updateGroupJobs,
  updateSelectedGroupTemplate,
  updateSelectedGroupTemplateSettings,
} from "../mutators/jobGroupCreationMutators";
import { resetJobStore } from "../mutators/jobMutators";
import {
  jobGroupCreationStore,
  type LocalJobSettings,
} from "../store/jobGroupCreationStore";
import { jobStore } from "../store/jobStore";
orchestrator(createJobGroupAction, () => {
  updateCreationMode("group");
});

orchestrator(updateSelectedGroupTemplatesAction, ({ selected }) => {
  updateSelectedGroupTemplate(selected);
  const setting = parseJsonStrOptional(selected.Settings, JobGroupSettings);
  updateSelectedGroupTemplateSettings(setting);
  // Fetch job group templates
  if (setting && setting.required !== undefined) {
    const predefinedJobs = new Array<LocalJobSettings>();
    setting.required.forEach((item) => {
      // Fetch job templates
      predefinedJobs.push({
        jobName: "<Job Name>",
        templateName: item.experimentName,
        configuration: undefined,
        localId: getRandomUUID(),
        type: "required",
      });
    });
    updateGroupJobs(predefinedJobs);
  } else {
    updateGroupJobs([]);
  }
});

orchestrator(addEditSubJobAction, ({ localId, mode }) => {
  const targetJob = jobGroupCreationStore.jobs.find(
    (item) => item.localId === localId,
  );
  const currentJobList = jobGroupCreationStore.jobs;
  if (targetJob === undefined) {
    // Fetch job templates
    const newJobId = getRandomUUID();
    currentJobList.push({
      jobName: "",
      templateName: "",
      configuration: undefined,
      localId: newJobId,
      type: "optional",
    });
    updateCreationMode(mode);
    updateCurrentLocalJobId(newJobId);
  } else {
    updateCreationMode(mode);
    updateCurrentLocalJobId(localId);
  }
});

orchestrator(deleteSubJobAction, ({ localId }) => {
  const targetJob = jobGroupCreationStore.jobs.filter(
    (item) => item.localId !== localId,
  );
  updateGroupJobs(targetJob);
});

orchestrator(saveSubJobAction, () => {
  // Fetch job group templates
  const newJobList = jobGroupCreationStore.jobs.map((item) => {
    if (item.localId === jobGroupCreationStore.currentLocalJobId) {
      const templateName = jobStore.selectedTemplate?.Name ?? item.templateName;
      return {
        ...item,
        jobName: jobStore.jobName,
        templateName: templateName,
        configuration: jobStore.configuration,
      };
    }
    return item;
  });
  updateGroupJobs(newJobList);
  updateCreationMode("group");
  updateCurrentLocalJobId(undefined);
  resetJobStore();
});

orchestrator(submitJobGroupAction, () => {
  // Fetch job group templates
  const jobsData: Record<string, CreateJobRouterRequestData> = {};
  jobGroupCreationStore.jobs.forEach((item) => {
    jobsData[item.localId] = {
      ExperimentName: item.templateName,
      JobName: `${item.jobName} - ${generateFormattedDateString()}`,
      Settings: JSON.stringify(item.configuration),
    };
  });
  const requiredJobs: JobOfGroup[] = jobGroupCreationStore.jobs
    .filter((item) => item.type === "required")
    .map((item) => {
      return {
        experimentName: item.templateName,
        localId: item.localId,
      };
    });
  const optionalJobs = jobGroupCreationStore.jobs
    .filter((item) => item.type === "optional")
    .map((item) => {
      return {
        experimentName: item.templateName,
        localId: item.localId,
      };
    });

  const groupSettings: JobGroupSettings = {
    required: requiredJobs,
    optional: optionalJobs,
  };
  const submitData: CreateGroupJobRouterParser = {
    GroupName: jobGroupCreationStore.groupName,
    Settings: JSON.stringify(groupSettings),
    GroupTemplateId: jobGroupCreationStore.selectedGroupTemplate?.Id,
    Jobs: jobsData,
  };
  createJobGroup(submitData).then(() => {
    updateCurrentPath("/");
    resetGroupCreationStore();
  });
});
