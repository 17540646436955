import { computed } from "mobx";
import {
  isMultiServiceTemplate,
  getTemplateType as sharedGetTemplateType,
  TemplateType,
} from "sydneyeval-shared";
import { jobStore } from "../store/jobStore";

export const getTemplateType = computed(() => {
  return sharedGetTemplateType(jobStore.selectedTemplate?.Type);
});

export const getMultiServiceTemplate = computed(() => {
  if (jobStore.selectedTemplate === undefined) {
    return false;
  }
  return isMultiServiceTemplate(jobStore.selectedTemplate?.Name);
});

export const supportEvaluationType = computed(() => {
  return (
    (getTemplateType.get() === TemplateType.Normal ||
      getMultiServiceTemplate.get()) &&
    jobStore.selectedTemplate !== undefined
  );
});
