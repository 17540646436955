import { Combobox, InfoLabel, Option } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import type { JobGroupTemplate } from "sydneyeval-shared";
import { updateSelectedGroupTemplatesAction } from "../../../actions/jobGroupActions";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { useGroupStyles } from "../groupStyles";

export const GroupTemplateView = observer(() => {
  const styles = useGroupStyles();
  const [inputCache, setInputCache] = useState<string | undefined>(undefined);

  const groupTemplates = jobGroupCreationStore.groupTemplates;
  const selected = jobGroupCreationStore.selectedGroupTemplate;

  const getTemplateDisplayString = (template: JobGroupTemplate | undefined) => {
    const templateId = template === undefined ? "" : template.Id;
    const templateName =
      template === undefined ? "" : `[${templateId}] ${template.Name}`;
    return templateName;
  };

  const renderTemplatesOptions = () => {
    return groupTemplates.map((template) => {
      return (
        <Option key={template.Id} value={`${template.Id}`}>
          {template.Name}
        </Option>
      );
    });
  };
  return (
    <div className={styles.block}>
      <InfoLabel required>
        <span className={styles.blockTitle}>Evaluation Template</span>
      </InfoLabel>
      <Combobox
        data-testid="group-template-combobox"
        className={styles.dropdownMenu}
        listbox={{ className: styles.listbox }}
        placeholder="Select a template"
        selectedOptions={[`${selected?.Id}`]}
        onOpenChange={(_, data) => setInputCache(data.open ? "" : undefined)}
        value={inputCache ?? getTemplateDisplayString(selected)}
        onChange={(ev) => {
          const value = ev.target.value;
          setInputCache(value.trim());
        }}
        onOptionSelect={(_, data) => {
          const currentSelectedTemplate = groupTemplates.find(
            (template) => `${template.Id}` === data.optionValue,
          );
          if (currentSelectedTemplate) {
            updateSelectedGroupTemplatesAction(currentSelectedTemplate);
          }
        }}
      >
        {renderTemplatesOptions()}
      </Combobox>
      {selected?.Description}
    </div>
  );
});
