import {
  anyObject,
  JobConfiguration,
  object,
  optional,
  parseJsonStrOptional,
  parseJsonStrWithDefault,
  str,
  unknown,
} from "sydneyeval-shared";
import { getAzureMLFileContent } from "../../../helpers/apiHelper";
import type { JobTemplate } from "../models/JobTemplate";
import {
  updateDefaultTemplateSettings,
  updateProductFilter,
} from "../mutators/jobMutators";
import { getQuickFilterDefaultTemplate } from "../selectors/getQuickFilterDefaultTemplate";
import { getBuildInProducts } from "../selectors/productSelectors";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";

const CloneJobStateParser = optional(
  object({
    clonedJobTemplateType: str,
    clonedExperimentName: str,
    clonedTemplateSettings: str,
  }),
);

const WrappedConfigParser = optional(
  object({
    config_file: object({
      path: str,
    }),
  }),
);

const WrappedSerializedConfigParser = optional(
  object({
    serialized_config: object({
      default: unknown,
    }),
  }),
);

const convertProductExperimentNameToBasicExperimentName = (name: string) => {
  if (name.startsWith("Product_")) {
    const productPrefix = "Product_" + name.split("_")[1] + "_";
    return name.replace(productPrefix, "");
  }
  if (name.startsWith("Scheduled_")) {
    return name.replace("Scheduled_", "");
  }
  return name;
};

const getProductName = (name: string) => {
  if (name.startsWith("Product_")) {
    return name.split("_")[1];
  }
  return undefined;
};

export const getDefaultTemplate = async (
  state: unknown,
): Promise<JobTemplate> => {
  const templates = jobStore.availableTemplates;
  const parsedState = CloneJobStateParser(state, "CloneJobStateParser");

  // If is cloning from a job
  if (parsedState !== undefined) {
    const clonedTemplateName =
      convertProductExperimentNameToBasicExperimentName(
        parsedState.clonedExperimentName,
      );
    const productName = getProductName(parsedState.clonedExperimentName);

    const clonedTemplate = templates.find(
      (template) => template.ExperimentName === clonedTemplateName,
    );
    if (clonedTemplate !== undefined) {
      //Update default setting

      const rawConfig = parseJsonStrWithDefault(
        clonedTemplate.Settings,
        anyObject,
        {},
      );
      if ("default_settings" in rawConfig) {
        const rawConfigObject = parseJsonStrWithDefault(
          clonedTemplate.Settings,
          JobConfiguration,
          {},
        );
        updateDefaultTemplateSettings(rawConfigObject?.default_settings);
      }

      // Update product name
      if (productName !== undefined) {
        const targetProduct = productSettingsStore.products.find(
          (_) =>
            _.displayName.replace(" ", "").toLowerCase() ===
            productName.toLowerCase(),
        );
        if (targetProduct !== undefined) {
          updateProductFilter(targetProduct.id);
        }
      } else {
        const builtInProducts = getBuildInProducts.get();
        const builtInProduct = builtInProducts.find(
          (product) =>
            product.templates.find(
              (template) => template.pipelineExpName === clonedTemplateName,
            ) !== undefined,
        );
        if (builtInProduct !== undefined) {
          updateProductFilter(builtInProduct.id);
        }
      }
      // If the Settings can be parsed with WrappedConfigParser,
      // then we will try to get the real settings content from AzureML (Basically for BingV2 and CWC)
      const wrappedJson = parseJsonStrOptional(
        parsedState.clonedTemplateSettings || clonedTemplate.Settings,
        WrappedConfigParser,
      );

      if (wrappedJson !== undefined) {
        try {
          const result = await getAzureMLFileContent(
            wrappedJson.config_file.path,
          );

          return {
            ...clonedTemplate,
            Settings: result ?? "{}",
          };
        } catch (e) {
          return {
            ...clonedTemplate,
            Settings: JSON.stringify(wrappedJson) ?? "{}",
          };
        }
      }

      // If the Settings can be parsed with WrappedSerializedConfigParser
      // then we will just return the Settings (Basically for BingV2 and CWC)
      const wrappedSerializedConfig = parseJsonStrOptional(
        parsedState.clonedTemplateSettings || clonedTemplate.Settings,
        WrappedSerializedConfigParser,
      );

      if (wrappedSerializedConfig !== undefined) {
        return {
          ...clonedTemplate,
          Settings:
            JSON.stringify(wrappedSerializedConfig.serialized_config.default) ??
            "{}",
        };
      }

      // If the Settings cannot be parsed with WrappedSettingsParser or WrappedSerializedConfigParser
      // then we will just return the Settings
      return {
        ...clonedTemplate,
        Settings: parsedState.clonedTemplateSettings,
      };
    }
  }

  return getQuickFilterDefaultTemplate.get();
};
