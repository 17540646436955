import {
  deletePropValueActionV2,
  generateRefreshTokenAction,
  updateDataSetsStringAction,
  updateJobNameAction,
  updateJsonStringAction,
  updateProductFilterAction,
  updatePropValueActionV2,
  updateSelectedEvaluationTypeAction,
  updateSelectedTemplateAction,
} from "../actions/jobActions";
import {
  deletePropValue,
  overrideProductPipelineSettings,
  updateBingAvailableClusters,
  updateDataSetsString,
  updateJobName,
  updateJsonString,
  updateJsonStringFromConfiguration,
  updateProductFilter,
  updatePropValueV2,
  updateSelectedEvaluationType,
  updateSelectedTemplate,
} from "../mutators/jobMutators";

import { cloneDeep, set } from "lodash";
import { orchestrator } from "satcheljs";
import {
  BingMetaDataResponse,
  isEyesOnEnvironment,
  isValidSettings,
  JobConfiguration,
  parseJsonStr,
  parseJsonStrWithDefault,
  TemplateType,
} from "sydneyeval-shared";
import {
  DeprecatedSydneyClientSecret,
  GeneralSydneyClientSecret,
  lmChecklistFile,
} from "../../../constants";
import { getSubstrateToken } from "../../../helpers/accessTokenHelper";
import {
  getBingClusters,
  getBingProductMetaData,
  getBingUserSets,
  getLoginUrl,
  ingestSyntheticData,
} from "../../../helpers/apiHelper";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import { isFeatureEnabled } from "../../../selectors/features";
import { store } from "../../../store/store";
import { ingestSyntheticDataAction } from "../actions/ingestSyntheticDataAction";
import { contributionServices } from "../contributionServices";
import { isSyntheticUserEnabled } from "../helpers/jobSyntheticUserHelper";
import { LocalCache } from "../models/LocalCache";
import {
  updateBingMetaData,
  updateStatusIsLoadingContentView,
  updateUserUploadedTestSet,
} from "../mutators/jobCreationMutators";
import {
  setPersonalAccount,
  setSelectedQuerySet,
  setSelectedUser,
  setTemplateQuerySet,
} from "../mutators/jobCreationSyntheticMutators";
import { updateJobPermission } from "../mutators/jobShareMutators";
import { getValue } from "../selectors/getJobPropV2";
import { getQuickFilterDefaultTemplate } from "../selectors/getQuickFilterDefaultTemplate";
import {
  getMultiServiceTemplate,
  getTemplateType,
} from "../selectors/getTemplateType";
import { jobCreationSyntheticStore } from "../store/jobCreationSyntheticStore";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";

// 1. Update prop in Configure
// 2. Update json from Configure
orchestrator(updateJobNameAction, ({ newName }) => {
  // Update prop in configure
  updateJobName(newName);
  // Update json
  updateJsonStringFromConfiguration();
});

orchestrator(updateSelectedTemplateAction, ({ newTemplate }) => {
  const templateTypeChanged =
    newTemplate.Type !== jobStore.selectedTemplate?.Type;
  updateSelectedTemplate(newTemplate);

  // If product register feature is enabled, and selected template has product and pipeline info,
  // we should overide the job template settings by product settings override
  if (
    newTemplate.ProductId !== undefined &&
    newTemplate.ProductPipelineId !== undefined
  ) {
    const targetProduct = productSettingsStore.products.find(
      (_) => _.id === newTemplate.ProductId,
    );
    const targetTemplate = targetProduct?.templates.find(
      (_) => _.id === newTemplate.ProductPipelineId,
    );

    // If we can find the target product template and
    // the product template's settings override is undefined,
    // we should use the new template's settings directly
    if (
      targetTemplate !== undefined &&
      targetTemplate.settingsOverride !== undefined
    ) {
      overrideProductPipelineSettings(targetTemplate.settingsOverride);
    }

    initSyntheticstore();
  }

  updateJobPermission([]);
  // If current template type is Normal or BizChatFlightReview, we should auto update userId
  // In this case, if the user id is not a microsoft account, we should set the evaluation type to FakeTenant
  // Otherwise, we should set the evaluation type to PersonalAccount and use the current user id
  const jobTemplateType = getTemplateType.get();
  const isMultiturnTemplate = getMultiServiceTemplate.get();

  const updateUserId = () => {
    const disableEyesOnPersonalAccount = isFeatureEnabled(
      "disable-eyeson-personal",
    );

    const disablePersonalAccount =
      disableEyesOnPersonalAccount &&
      isEyesOnEnvironment(jobStore.selectedTemplate?.EnvType ?? "");
    const userIdInSetting = getValue("configs_in_json.conversations.user_id");

    const updateAccount = () => {
      if (disablePersonalAccount) {
        updateSelectedEvaluationTypeAction("FakeTenant");
        updatePropValueActionV2({
          prop: "configs_in_json.conversations.user_id",
          newData: "MeganBowen@meinbizchat.onmicrosoft.com",
        });
      } else {
        updateSelectedEvaluationTypeAction("PersonalAccount");
        updatePropValueActionV2({
          prop: "configs_in_json.conversations.user_id",
          newData: store.account?.username,
        });
      }
    };

    // user id not defined we should use the current user id
    if (userIdInSetting === undefined) {
      updateAccount();
    }
    // if user id is microsoft account, we should use the current user id
    else if (userIdInSetting.endsWith("@microsoft.com")) {
      updateAccount();
    }
    // if user id is not microsoft account, we should use the current user id
    else {
      updateSelectedEvaluationTypeAction("FakeTenant");
    }
  };

  if (isMultiturnTemplate) {
    updateUserId();
  }

  if (
    jobTemplateType === TemplateType.Normal ||
    jobTemplateType === TemplateType.BizChatFlightReview ||
    jobTemplateType === TemplateType.AIHub
  ) {
    updateUserId();
    // If append-timezone feature is enabled, we should append timezone offset to exp_configs
    if (isFeatureEnabled("append-timezone")) {
      // Clone the configuration to avoid modifying the original one
      const clonedConfig = cloneDeep(jobStore.configuration);

      // get exp_configs from jobStore
      const exp_configs = getValue("configs_in_json.conversations.exp_configs");
      if (exp_configs) {
        // Get user local timezone offset in hours
        const timeZoneOffset = new Date().getTimezoneOffset();
        const offsetInHours = Math.abs(timeZoneOffset / 60);

        // Append timezone offset to exp_configs
        for (const exp_config of exp_configs) {
          exp_config.sydney ??= {};
          exp_config.sydney.locationInfo ??= {};
          exp_config.sydney.locationInfo.timeZoneOffset = offsetInHours;
        }
      }
      if (clonedConfig) {
        // Update the cloned configuration with the new exp_configs
        set(
          clonedConfig,
          "configs_in_json.conversations.exp_configs",
          exp_configs,
        );

        //check if the new configuration is valid for template settings schema
        if (
          isValidSettings(
            JSON.stringify(clonedConfig),
            newTemplate.SettingsSchema,
          )
        ) {
          // Update the configuration in the store if it is valid
          updatePropValueActionV2({
            prop: "configs_in_json.conversations.exp_configs",
            newData: exp_configs,
          });
        }
      }
    }

    // if input_file is set, we should clear it
    if (getValue("configs_in_json.conversations.input_file") !== undefined) {
      updatePropValueActionV2({
        prop: "configs_in_json.conversations.input_file",
        newData: "",
      });
    }
  }

  // For OPG jobs with "configs_in_json.conversations.input_folder" not undefined, always clean the input folder and ask user to upload again
  if (
    jobTemplateType === TemplateType.OPG &&
    getValue("configs_in_json.conversations.input_folder") !== undefined
  ) {
    updatePropValueActionV2({
      prop: "configs_in_json.conversations.input_folder",
      newData: "",
    });
  }

  // If a job config contains "create_sbs_experiment", just override to true
  if (getValue("create_sbs_experiment") !== undefined) {
    updatePropValueActionV2({
      prop: "create_sbs_experiment",
      newData: true,
    });
  }

  // We will override "configs_in_json.sydney_client_secret" to the new value when:
  // 1. feature "refresh-token-migration" is enabled
  // 2. the current job config contains "configs_in_json.sydney_client_secret"
  // 3. the current sydney_client_secret is the deprecated value
  const currentSydneyClientSecret = getValue(
    "configs_in_json.sydney_client_secret",
  );
  if (
    isFeatureEnabled("refresh-token-migration") &&
    currentSydneyClientSecret === DeprecatedSydneyClientSecret
  ) {
    updatePropValueActionV2({
      prop: "configs_in_json.sydney_client_secret",
      newData: GeneralSydneyClientSecret,
    });
  }

  if (
    jobTemplateType === TemplateType.BingV2 ||
    jobTemplateType === TemplateType.CWC
  ) {
    updateStatusIsLoadingContentView(true);

    const templateType =
      jobTemplateType === TemplateType.BingV2 ? "Bing" : "CWC";
    if (templateTypeChanged) {
      getBingUserSets(templateType).then((sets) => {
        updateUserUploadedTestSet(sets.map((_) => _.name));
      });
    }

    const getClusters = getSubstrateToken().then((_) => {
      return getBingClusters({
        SubstrateToken: _,
        CallerFileNameLine: "JobOrchestrators",
        ClientRequestId: getRandomUUID(),
        ProcessName: "SEVAL",
      });
    });

    Promise.all([getBingProductMetaData(templateType), getClusters]).then(
      ([metadata, availableClusters]) => {
        const metaData = parseJsonStrWithDefault(
          metadata,
          BingMetaDataResponse,
          undefined,
        );
        updateBingMetaData(metaData);
        updateBingAvailableClusters(availableClusters);

        const newSetting = parseJsonStr(
          newTemplate?.Settings,
          JobConfiguration,
          "BingTemplate",
        );

        const isValidNewCluster =
          newSetting &&
          newSetting.cluster &&
          availableClusters.includes(newSetting.cluster);
        const newCluster = isValidNewCluster
          ? newSetting.cluster
          : availableClusters[0];

        updatePropValueActionV2({
          prop: "cluster",
          newData: newCluster,
        });

        const isValidNewScenario = newSetting && newSetting.scenario;
        const newScenario = isValidNewScenario
          ? newSetting.scenario
          : "default";

        updatePropValueActionV2({
          prop: "scenario",
          newData: newScenario,
        });

        updatePropValueActionV2({
          prop: "user_id",
          newData: store.account?.username,
        });
        updateStatusIsLoadingContentView(false);
      },
    );
  }
});

orchestrator(updateDataSetsStringAction, ({ dataSetsStr }) => {
  updateDataSetsString(dataSetsStr);
});

orchestrator(updateJsonStringAction, ({ jsonStr }) => {
  updateJsonString(jsonStr);
});

orchestrator(updatePropValueActionV2, ({ update }) => {
  updatePropValueV2(update);
  updateJsonStringFromConfiguration();
});

orchestrator(deletePropValueActionV2, ({ update }) => {
  deletePropValue(update);
  updateJsonStringFromConfiguration();
});

orchestrator(updateProductFilterAction, ({ productId }) => {
  const localCache = contributionServices.getLocalCache?.(LocalCache);
  contributionServices.setLocalCache?.({
    ...localCache,
    productFilter: productId,
  });
  updateProductFilter(productId);
  if (jobStore.availableTemplates.length > 0) {
    updateSelectedTemplateAction(getQuickFilterDefaultTemplate.get());
  }
});

orchestrator(updateSelectedEvaluationTypeAction, ({ newEvaluationType }) => {
  const oldEvaluationType = jobStore.selectedEvaluationType;

  if (oldEvaluationType !== newEvaluationType) {
    if (newEvaluationType === "PersonalAccount") {
      updatePropValueActionV2({
        prop: "configs_in_json.conversations.lmchecklist_input_file",
        newData: lmChecklistFile.personalAccount,
      });
    } else if (newEvaluationType === "FakeTenant") {
      updatePropValueActionV2({
        prop: "configs_in_json.conversations.lmchecklist_input_file",
        newData: lmChecklistFile.fakeTenant,
      });
    }
    updateSelectedEvaluationType(newEvaluationType);
  }
});

orchestrator(generateRefreshTokenAction, ({ callback }) => {
  getLoginUrl()
    .then((url) => {
      window.open(url, "_blank");
    })
    .catch(() => undefined)
    .finally(() => {
      callback?.();
    });
});

orchestrator(ingestSyntheticDataAction, (actionMessage) => {
  const { containerName, blobName, submittedBy, onSuccess, onError } =
    actionMessage;
  ingestSyntheticData({
    ContainerName: containerName,
    BlobName: blobName,
    SubmittedBy: submittedBy,
  })
    .then(onSuccess)
    .catch(onError);
});

const initSyntheticstore = () => {
  if (!isSyntheticUserEnabled()) {
    return;
  }

  const jobConfig = jobStore.configuration as JobConfiguration;
  if (!jobConfig?.configs_in_json?.conversations) {
    return;
  }

  setPersonalAccount(jobConfig.configs_in_json.conversations.user_id ?? "");
  setTemplateQuerySet(jobConfig.configs_in_json.conversations.input_file ?? "");

  const userInfos = jobCreationSyntheticStore.selectedUser.split(" ");
  const syntheticUserId = `${userInfos[0]}@${userInfos[1]}.onmicrosoft.com`;
  if (jobConfig.configs_in_json.conversations.user_id !== syntheticUserId) {
    setSelectedUser("");
    setSelectedQuerySet("");
    jobConfig.configs_in_json.conversations.is_synthetic = false;
  }
};
