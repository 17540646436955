import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  shorthands,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { updateJobPriority } from "../../../../../helpers/apiHelper";
import { resetJobPriorityStateAction } from "../../../actions/jobPriorityActions";
import { updateResultJob } from "../../../mutators/jobResultMutators";
import { jobPriorityStore } from "../../../store/jobPriorityStore";
import { resultStore } from "../../../store/resultStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      flex: 1,
    },
    buttonGroup: {
      alignSelf: "flex-end",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const RemoveJobPriorityDialog = observer(() => {
  const styles = useStyles();
  const toast = useToast();

  const handleConfirmAction = () => {
    toast.onToastStart("Removing job priority...");
    if (jobPriorityStore.currentJobId) {
      updateJobPriority({
        JobId: jobPriorityStore.currentJobId,
        IsPriorityJob: false,
        ADOLink: "",
      })
        .then(
          () => {
            toast.onToastSuccess("Successfully removed job priority");
            const currentJob = resultStore.resultJob;
            if (currentJob) {
              currentJob.IsPriorityJob = false;
              currentJob.PriorityJobADOLink = "";
              updateResultJob(currentJob);
            }
          },
          (error) => {
            toast.onToastFailure(
              `Failed to remove job priority: ${error.message}`,
            );
          },
        )
        .finally(() => {
          resetJobPriorityStateAction();
        });
    } else {
      toast.onToastFailure(`Failed to remove job priority: Job ID is missing`);
      resetJobPriorityStateAction();
    }
  };

  return (
    <Dialog open={jobPriorityStore.isRemoveJobPriorityDialogOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Remove Job Priority</DialogTitle>
          <DialogContent>
            <div className={styles.root}>
              <Body1>
                You're about to remove the priority tag from this job. This
                action can't be undone. Do you want to proceed?
              </Body1>
              <DialogActions className={styles.buttonGroup}>
                <Button appearance="primary" onClick={handleConfirmAction}>
                  Confirm
                </Button>
                <Button
                  onClick={() => {
                    resetJobPriorityStateAction();
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
