import { createStore } from "satcheljs";
import type {
  JobConfiguration,
  JobGroupSettings,
  JobGroupTemplate,
} from "sydneyeval-shared";
export interface LocalJobSettings {
  jobName: string;
  templateName: string;
  configuration: JobConfiguration | undefined;
  localId: string;
  type?: string;
}

export type JobGroupCreationMode = "group" | "add" | "edit";

interface IJobGroupCreationStore {
  isLoading: boolean;
  groupTemplates: JobGroupTemplate[];
  selectedGroupTemplate: JobGroupTemplate | undefined;
  selectedGroupTemplateSettings: JobGroupSettings | undefined;
  creationMode: JobGroupCreationMode | undefined;
  groupName: string;
  groupSettings: JobGroupSettings | undefined;
  jobs: LocalJobSettings[];
  currentLocalJobId: string | undefined;
  isDismissDialogOpen?: boolean;
}

export const initJobGroupCreationStore: IJobGroupCreationStore = {
  isLoading: true,
  groupTemplates: [],
  selectedGroupTemplate: undefined,
  selectedGroupTemplateSettings: undefined,
  creationMode: undefined,
  groupName: "",
  groupSettings: undefined,
  jobs: [],
  currentLocalJobId: undefined,
};

export const jobGroupCreationStore = createStore<IJobGroupCreationStore>(
  "jobGroupCreationStore",
  Object.assign({}, initJobGroupCreationStore),
)();
