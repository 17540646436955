import {
  Body1,
  Checkbox,
  Dropdown,
  makeStyles,
  mergeClasses,
  Option,
  shorthands,
  Spinner,
} from "@fluentui/react-components";

import { ErrorCircle20Filled, Filter24Regular } from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { uniqByObject } from "sydneyeval-shared";
import { Tip } from "../../../../../components/Shared/Tip";
import { harmfulRAISegment } from "../../../../../constants/constants";
import { MetricJudgementViewMetricKey } from "../../../../../constants/MChatDisplayedMetrics";
import { openSidePane } from "../../../../../mutators/sidePaneMutators";
import { mapMetricsKey } from "../../../helpers/metricsHelper";
import { segmentFilterValue } from "../../../helpers/renderMetricsHelper";
import {
  updatedselectedMChatMetrics,
  updateFilters,
  updateRaiEnabled,
  updateUtterNum,
} from "../../../mutators/jobDetailsMutators";
import {
  allUtterances,
  filteredUtterances,
} from "../../../selectors/getGeneralMetrics";
import { getRedactedCount } from "../../../selectors/getPrivacyFilterSummary";
import { isMultiturnJob } from "../../../selectors/jobDetailHelper";
import {
  defaultUttsPerPage,
  jobDetailStore,
} from "../../../store/jobDetailStore";
import { resultStore } from "../../../store/resultStore";
import { MetricJudgementView } from "../SidePane/MetricJudgementView";
import { MChatMetricsBody } from "./MChatMetricsTableComponents/MChatMerticsBody";
import { useSharedTableStyles } from "./shardTableStyles";
const useStyles = makeStyles({
  metricNameCell: {
    width: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
  },
  subTitleCell: {
    fontSize: "12px",
  },
  utteranceHeaderCell: {
    paddingLeft: "13px",
  },
  subTableCell: {
    paddingLeft: "calc(0.25rem + 36px)",
    paddingRight: "0.75rem",
  },
  positiveCell: {
    backgroundColor: "rgba(85, 177, 126, 0.30)",
  },
  negativeCell: {
    backgroundColor: "rgba(221, 66, 66, 0.30)",
  },
  tbody: {
    backgroundColor: "white",
  },
  searchIcon: {
    verticalAlign: "bottom",
  },
  utteranceCell: {
    minWidth: "200px",
  },
  utterance: {
    justifyContent: "space-between",
    ":hover Button": {
      display: "inline-flex",
    },
    ":not(:hover) Button": {
      display: "none",
    },
  },
  metricFeedbackButton: {
    marginLeft: "auto",
    height: "16px",
  },
  privacyFilterFeedbackButton: {
    height: "16px",
    width: "16px",
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  fitContentWidth: {
    width: "fit-content",
  },
  spaceBetweenRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errorIcon: {
    display: "flex",
    alignItems: "center",
  },
  spinner: {
    marginTop: "20px",
    height: "100px",
  },
});

export const MChatMetricsTable = observer(() => {
  const { jobId, metricKey, uttquery } = useParams();

  const utterances = allUtterances.get();
  const filteredUttr = filteredUtterances.get();
  const segments = uniqByObject(utterances.map((_) => _.segment)).sort();

  const sharedStyles = useSharedTableStyles();
  const styles = useStyles();

  const [hasMore, setHasMore] = React.useState(true);
  const [autoOpenSidePane, setAutoOpenSidePane] = React.useState(
    jobId !== undefined && metricKey !== undefined && uttquery !== undefined,
  );

  const [raiContent, setRaiContent] = useState(jobDetailStore.isRaiEnabled);

  useEffect(() => {
    updateUtterNum(Math.min(defaultUttsPerPage, jobDetailStore.utterNum));
    setHasMore(filteredUttr.length > jobDetailStore.utterNum);
  }, [filteredUttr.length]);

  const privacyFilterTooltip =
    "Total Utterances: " +
    utterances.length +
    "\n Utterances hit privacy filter: " +
    getRedactedCount +
    " (" +
    (utterances.length > 0
      ? ((100 * getRedactedCount.get()) / utterances.length).toFixed(0)
      : "0") +
    "%)";

  const loadMore = React.useCallback(() => {
    const utt = filteredUtterances.get();
    if (utt && jobDetailStore.utterNum >= utt.length) {
      setHasMore(false);
    } else {
      updateUtterNum(jobDetailStore.utterNum + defaultUttsPerPage);
    }
  }, [jobDetailStore.utterNum, utterances]);

  const loader = () => (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );

  React.useEffect(() => {
    const scrollableContainer = document.getElementById(
      "mainViewScrollContainer",
    );
    if (scrollableContainer) {
      const { clientHeight, scrollHeight } = scrollableContainer;

      if (scrollHeight <= clientHeight && hasMore) {
        loadMore();
      }
    }
  }, [hasMore, loadMore]);

  React.useEffect(() => {
    if (autoOpenSidePane) {
      if (metricKey === undefined || uttquery === undefined) {
        return;
      }
      try {
        const parseMetricKey = MetricJudgementViewMetricKey(
          metricKey,
          "MetricJudgementViewMetricKey",
        );

        if (utterances.find((utt) => utt.query === uttquery)) {
          setAutoOpenSidePane(false);
          handleUterranceClick(parseMetricKey, uttquery);
        }
      } catch {
        return;
      }
    }
  }, [metricKey, uttquery, utterances]);

  const handleUterranceClick = (
    key: MetricJudgementViewMetricKey | undefined,
    query: string,
  ) => {
    if (key !== undefined) {
      openSidePane(() => {
        return (
          <MetricJudgementView
            query={query}
            jobId={resultStore.resultJob?.JobId ?? ""}
            jobCreator={resultStore.resultJob?.CreatorSmtpAddress}
            metricKey={key}
            jobIdForShare={jobId}
          />
        );
      });
    }
  };

  const handleSegmentFilterChange = (segment: string) => {
    let currentSegments = [...jobDetailStore.filters];
    if (jobDetailStore.filters.includes(segment)) {
      currentSegments = currentSegments.filter((item) => item !== segment);
    } else {
      currentSegments.push(segment);
    }
    updateFilters(currentSegments);
  };

  const handleMetricsFilterChange = (metrics: string) => {
    let currentMetrics = [...jobDetailStore.selectedMChatMetrics];
    if (jobDetailStore.selectedMChatMetrics.includes(metrics)) {
      currentMetrics = currentMetrics.filter((item) => item !== metrics);
    } else {
      currentMetrics.push(metrics);
    }
    updatedselectedMChatMetrics(currentMetrics);
  };

  const hasTableData = computed(() => {
    return filteredUtterances.get().length > 0;
  });

  const infiniteScrollDataLength = computed(() => {
    return Math.min(jobDetailStore.utterNum, filteredUtterances.get().length);
  });

  const isMultiturn = isMultiturnJob.get();

  if (hasTableData.get() === false && !raiContent) {
    return <div>{resultStore.loadingQueue.length === 0 && "No Data."}</div>;
  }

  return (
    <>
      <div className={styles.spaceBetweenRow}>
        <div
          className={mergeClasses(
            sharedStyles.filterBar,
            styles.fitContentWidth,
          )}
        >
          <div className={sharedStyles.filtersGroup}>
            <Filter24Regular className={sharedStyles.filterIcon} />
            {segments.length > 0 && (
              <Dropdown
                data-testid="segment-filter"
                className={sharedStyles.filterBarItem}
                multiselect={true}
                placeholder="Segment"
                positioning={"below"}
                value={segmentFilterValue(jobDetailStore.filters)}
                selectedOptions={jobDetailStore.filters}
                onOptionSelect={(_, option) =>
                  handleSegmentFilterChange(option.optionValue as string)
                }
              >
                {segments.map((segment: string, index) => (
                  <Option key={index} value={segment}>
                    {segment}
                  </Option>
                ))}
              </Dropdown>
            )}
            {jobDetailStore.metricsToDisplay.length > 0 && (
              <Dropdown
                data-testid="metric-filter"
                className={sharedStyles.filterBarItem}
                multiselect={true}
                placeholder="Metrics"
                positioning={"below"}
                value={
                  jobDetailStore.selectedMChatMetrics.length > 0
                    ? mapMetricsKey(
                        jobDetailStore.selectedMChatMetrics[0],
                      ).concat("...")
                    : undefined
                }
                selectedOptions={jobDetailStore.selectedMChatMetrics}
                onOptionSelect={(_, option) =>
                  handleMetricsFilterChange(option.optionValue as string)
                }
              >
                {jobDetailStore.metricsToDisplay.map(
                  (metric: string, index) => (
                    <Option key={index} value={metric}>
                      {mapMetricsKey(metric)}
                    </Option>
                  ),
                )}
              </Dropdown>
            )}
            {isMultiturn && (
              <Dropdown
                data-testid="turn-filter"
                className={sharedStyles.filterBarItem}
                multiselect={false}
                placeholder="Last Turn"
                positioning={"below"}
              >
                <Option key={"last-turn"} value={"last-turn"}>
                  Last Turn
                </Option>
              </Dropdown>
            )}
          </div>
          <div>
            <Checkbox
              checked={raiContent}
              onChange={() => {
                const newRaiContent = !raiContent;
                const currentSegments = [...jobDetailStore.filters];
                const isRaiFilterIncluded =
                  jobDetailStore.filters.includes(harmfulRAISegment);
                if (newRaiContent) {
                  if (!isRaiFilterIncluded) {
                    currentSegments.push(harmfulRAISegment);
                  }
                } else {
                  if (isRaiFilterIncluded) {
                    currentSegments.splice(
                      currentSegments.indexOf(harmfulRAISegment),
                      1,
                    );
                  }
                }
                updateFilters(currentSegments);
                updateRaiEnabled(newRaiContent);
                setRaiContent(newRaiContent);
              }}
            />
            <span style={{ color: "dimgray", fontSize: "14px" }}>
              &nbsp;Show Sensitive RAI Queries (
              <a
                href="https://microsoft.sharepoint-df.com/:w:/t/CI_RAI/EXqNzIO-P6BNgR4QUshk8NkBPTERl-SHHnOGwSiftoZAbQ?e=zxfV9C"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disclaimer
              </a>
              )
            </span>
          </div>
        </div>

        <Tip
          withArrow
          content={
            <span className={sharedStyles.preLine}>{privacyFilterTooltip}</span>
          }
          relationship="label"
        >
          <div className={styles.errorIcon}>
            <ErrorCircle20Filled />
            <Body1>
              <u>Privacy Filter Summary</u>
            </Body1>
          </div>
        </Tip>
      </div>

      <InfiniteScroll
        scrollThreshold={"50px"}
        dataLength={infiniteScrollDataLength.get()}
        next={loadMore}
        hasMore={hasMore}
        loader={loader()}
        scrollableTarget={"mainViewScrollContainer"}
      >
        <MChatMetricsBody />
      </InfiniteScroll>
    </>
  );
});
