import { Dropdown, Option } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  resetLMCheckListFilter,
  updatedLMChecklistViewMode,
} from "../../../mutators/jobDetailsMutators";
import { jobDetailStore } from "../../../store/jobDetailStore";
import { useResultViewStyles } from "../sharedStyles";
import { LMChecklistAssertionsTable } from "../Table/LMChecklistAssertionsTable";
import { LMChecklistQueryTable } from "../Table/LMChecklistQueryTable";

export const LMChecklistView = observer(() => {
  // TODO: Support Segmnent
  const styles = useResultViewStyles();

  const {
    lmchecklistDetailsResponse,
    lmchecklistAssertionsResponse,
    LMCheckListViewMode,
  } = jobDetailStore;

  const renderTables = () => (
    <div className={styles.root}>
      <div className={styles.actionBar}>
        <Dropdown
          data-testid="LMChecklistViewModeDropdown"
          className={styles.dropdown}
          value={`${LMCheckListViewMode} View`}
          selectedOptions={[LMCheckListViewMode]}
          onOptionSelect={(_, option) => {
            switch (option.optionValue) {
              case "Query":
                updatedLMChecklistViewMode("Query");
                break;
              case "Assertion":
                updatedLMChecklistViewMode("Assertion");
                break;
            }
            resetLMCheckListFilter();
          }}
        >
          <Option value={"Query"}>Query View</Option>
          <Option value={"Assertion"}>Assertion View</Option>
        </Dropdown>
      </div>
      {LMCheckListViewMode === "Query" &&
        lmchecklistDetailsResponse &&
        lmchecklistAssertionsResponse && <LMChecklistQueryTable />}
      {LMCheckListViewMode === "Assertion" && lmchecklistAssertionsResponse && (
        <LMChecklistAssertionsTable />
      )}
    </div>
  );

  return (
    <>
      {lmchecklistDetailsResponse &&
        lmchecklistAssertionsResponse &&
        renderTables()}
    </>
  );
});
