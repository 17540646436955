import { createStore } from "satcheljs";
import type { JobGroupData } from "sydneyeval-shared";
import type { Job } from "../models/Job";

interface IJobListStore {
  mode: "job" | "group";
  jobs: Job[];
  groups: JobGroupData[];
  groupExpandIndex: number | undefined;
}

export const initJobListStore: IJobListStore = {
  mode: "job",
  jobs: [],
  groups: [],
  groupExpandIndex: undefined,
};

export const jobListStore = createStore<IJobListStore>(
  "jobListStore",
  Object.assign({}, initJobListStore),
)();
