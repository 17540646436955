import { computed } from "mobx";
import type { SendPriorityJobNotificationRequestData } from "sydneyeval-shared";
import { BingMetaDataResponse, parseJsonStrOptional } from "sydneyeval-shared";
import {
  getBingProductMetaData,
  getCustomLMChecklistSets,
  getGroupTemplates,
  getJobTemplates,
  getPublicLMChecklistSets,
  listProducts,
  sendPriorityJobNotification,
} from "../../../helpers/apiHelper";
import { perfWrapper } from "../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../selectors/features";
import {
  updateJsonStringAction,
  updateSelectedTemplateAction,
} from "../actions/jobActions";
import { resetJobPriorityStateAction } from "../actions/jobPriorityActions";
import type { JobTemplate } from "../models/JobTemplate";
import {
  updateCustomizedLMChecklistSets,
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../mutators/jobCreationFileMutators";
import {
  resetJobCreationStore,
  updateStatusIsLoading,
} from "../mutators/jobCreationMutators";
import {
  updateAvailableGroupTemplate,
  updateLoadingMode,
} from "../mutators/jobGroupCreationMutators";
import {
  resetJobStore,
  updateAvailableTemplates,
  updateJobName,
} from "../mutators/jobMutators";
import {
  updateProductJobTemplates,
  updateProductMetaData,
  updateProducts,
} from "../mutators/updateProductSettings";
import { jobGroupCreationStore } from "../store/jobGroupCreationStore";
import { jobStore } from "../store/jobStore";
import { getDefaultTemplate } from "./jobCloneHelper";
import { refreshQuerySets } from "./jobCreationHelper";

const handleJobTemplates = async (
  state: unknown,
  jobTemplates: JobTemplate[],
) => {
  const templates = jobTemplates;

  updateAvailableTemplates(templates);

  if (isEditGroupJob.get()) {
    initAddOrEditJobData();
  } else {
    const defaultTemplate = await getDefaultTemplate(state);
    if (defaultTemplate) {
      updateSelectedTemplateAction(defaultTemplate);
    }
  }
};

export const initCreationPageStore = (state: unknown) => {
  updateStatusIsLoading(true);
  perfWrapper(
    "LoadCreateJobPage",
    Promise.all([
      getJobTemplates(),
      refreshQuerySets(),
      getCustomLMChecklistSets(),
      getPublicLMChecklistSets(),
      listProducts(),
      getBingProductMetaData("Bing").catch(() => undefined),
      getBingProductMetaData("CWC").catch(() => undefined),
    ]).then(
      ([
        jobTemplates,
        customQS,
        customLMChecklist,
        publicLMChecklist,
        products,
        bingMetaData,
        cwcMetaData,
      ]) => {
        updateProducts(products);
        updateProductJobTemplates(jobTemplates);
        updateProductMetaData(
          parseJsonStrOptional(bingMetaData, BingMetaDataResponse),
          parseJsonStrOptional(cwcMetaData, BingMetaDataResponse),
        );
        updateCustomizedQuerySets(customQS);
        updateCustomizedLMChecklistSets(customLMChecklist);
        updatePublicLMChecklistSets(publicLMChecklist);
        handleJobTemplates(state, jobTemplates);
        updateStatusIsLoading(false);
      },
    ),
  );
  return () => {
    resetJobStore();
    resetJobCreationStore();
    resetJobPriorityStateAction();
  };
};

export const updateInfoAndSendNotification = (
  jobId: number,
  name: string,
  address: string,
) => {
  const updatedManagerInfo: SendPriorityJobNotificationRequestData = {
    JobId: jobId,
    LeaderName: name,
    LeaderAddress: address,
  };
  sendPriorityJobNotification(updatedManagerInfo);
};

export const isCreateGroup = computed(() => {
  if (isFeatureEnabled("enable-job-group")) {
    return jobGroupCreationStore.creationMode === "group";
  }
  return false;
});

// const mockJobGroupTemplateSetting: JobGroupSettings = {
//   required: [
//     {
//       experimentName: "BizChat_Evaluation_Flight_Review_For_Avalon",
//       description: "This job is for the first job in the template.",
//     },
//     {
//       experimentName: "BizChat_Evaluation_All_Metrics_For_GPT",
//       description: "This job is for the second job in the template.",
//     },
//     {
//       experimentName: "BizChat_Evaluation_Flight_Review",
//       description: "This job is for the third job in the template.",
//     },
//   ],
// };

// const mockJobGroupTemplate: JobGroupTemplate = {
//   Id: 1,
//   Name: "BizChat Evaluation",
//   Settings: JSON.stringify(mockJobGroupTemplateSetting),
//   CreatedAt: "2021-08-24T09:00:00Z",
//   LastUpdateTime: "2021-08-24T09:00:00Z",
//   CreatedBy: "John Doe",
//   Description: "This is a mock job group template.",
//   Type: "Predefined",
// };

export const initJobGroupData = () => {
  getGroupTemplates().then((data) => {
    updateAvailableGroupTemplate(data);
  });
  // updateAvailableGroupTemplate([mockJobGroupTemplate]);
  updateLoadingMode(false);
};

export const isEditGroupJob = computed(() => {
  return (
    jobGroupCreationStore.creationMode === "add" ||
    jobGroupCreationStore.creationMode === "edit"
  );
});
export const initAddOrEditJobData = () => {
  if (!isEditGroupJob.get()) {
    return;
  }
  const currentJob = jobGroupCreationStore.jobs.find(
    (job) => job.localId === jobGroupCreationStore.currentLocalJobId,
  );

  if (currentJob) {
    const currentTemplate = jobStore.availableTemplates.find(
      (item) => item.Name === currentJob.templateName,
    );
    if (currentTemplate) {
      updateSelectedTemplateAction(currentTemplate);
    } else {
      updateSelectedTemplateAction(jobStore.availableTemplates[0]);
    }
    updateJobName(currentJob.jobName);
    if (currentJob.configuration !== undefined) {
      updateJsonStringAction(JSON.stringify(currentJob.configuration, null, 2));
    }
  }
};
