import { Button } from "@fluentui/react-components";
import { ArrowLeft32Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { saveSubJobAction } from "../../../actions/jobGroupActions";
import {
  confirmSBSValidator,
  isJobCreationEditDisabled,
  priorityJobValidator,
  servicePrevalidation,
  tokenPopupValidator,
} from "../../../helpers/jobCreationHelper";
import { updateStatusIsVerifying } from "../../../mutators/jobCreationMutators";
import {
  updateCreationMode,
  updateCurrentLocalJobId,
} from "../../../mutators/jobGroupCreationMutators";
import { getJobCreationErrorMessage } from "../../../selectors/creationError/getJobCreationError";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { jobStore } from "../../../store/jobStore";
import { useGroupStyles } from "../groupStyles";

export const JobCreationHeader = observer(() => {
  const styles = useGroupStyles();
  const toast = useToast();

  const onSaveClicked = React.useCallback(() => {
    if (jobCreationStore.isSubmitting || jobCreationStore.isVerifying) {
      return;
    }

    toast.onToastStart("Verifying Job Creation...");
    updateStatusIsVerifying(true);

    if (jobStore.selectedTemplate === undefined) {
      toast.onToastFailure("Please select a template");
      updateStatusIsVerifying(false);
      return;
    }

    const errorMessage = getJobCreationErrorMessage.get();
    if (errorMessage !== undefined) {
      toast.onToastFailure(errorMessage);
      updateStatusIsVerifying(false);
      return;
    }

    Promise.resolve()
      .then(() => confirmSBSValidator())
      .then(() => priorityJobValidator())
      .then(() => tokenPopupValidator())
      .then(() => servicePrevalidation())
      .then(() => {
        toast.onToastSuccess("Job Creation Verified");
        saveSubJobAction();
      })
      .catch((error) => {
        toast.onToastFailure(error.toString());
      })
      .finally(() => {
        updateStatusIsVerifying(false);
      });
  }, []);

  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <Button
          disabled={isJobCreationEditDisabled.get()}
          appearance={"primary"}
          onClick={onSaveClicked}
        >
          Save
        </Button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.rowWithSmallGap}>
            <Button
              data-testid="back-button"
              appearance={"subtle"}
              icon={<ArrowLeft32Filled />}
              onClick={() => {
                updateCreationMode("group");
                updateCurrentLocalJobId(undefined);
              }}
            ></Button>

            <span className={styles.headerTitle}>
              {jobGroupCreationStore.creationMode === "add"
                ? "Create a new job"
                : "Edit Job"}
            </span>
          </div>
        </div>
        <div>
          {!jobGroupCreationStore.isLoading && renderShareSubmitButton()}
        </div>
      </div>
    );
  };
  return <div>{renderHeader()}</div>;
});
