"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobGroupData = exports.JobOfGroupSubmitData = exports.JobGroupTemplate = exports.JobGroupSettings = exports.JobOfGroup = void 0;
const tools_1 = require("../tools");
exports.JobOfGroup = (0, tools_1.object)({
    experimentName: tools_1.str,
    jobId: (0, tools_1.optional)(tools_1.str),
    description: (0, tools_1.optional)(tools_1.str),
    localId: (0, tools_1.optional)(tools_1.str),
});
exports.JobGroupSettings = (0, tools_1.object)({
    required: (0, tools_1.optional)((0, tools_1.array)(exports.JobOfGroup)),
    optional: (0, tools_1.optional)((0, tools_1.array)(exports.JobOfGroup)),
});
exports.JobGroupTemplate = (0, tools_1.object)({
    Id: tools_1.num,
    Name: tools_1.str,
    Settings: tools_1.str,
    CreatedAt: tools_1.str,
    LastUpdateTime: tools_1.str,
    CreatedBy: tools_1.str,
    Description: (0, tools_1.optional)(tools_1.str),
    Type: tools_1.str,
});
exports.JobOfGroupSubmitData = (0, tools_1.object)({
    localId: tools_1.str,
    jobId: (0, tools_1.optional)(tools_1.str),
});
exports.JobGroupData = (0, tools_1.object)({
    ID: tools_1.num,
    GroupTemplateId: tools_1.num,
    Name: tools_1.str,
    CreatedBy: tools_1.str,
    CreatedAt: tools_1.str,
    Settings: (0, tools_1.optional)(exports.JobGroupSettings),
    Jobs: (0, tools_1.optional)((0, tools_1.array)(tools_1.anyObject)),
});
