import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  shorthands,
  Subtitle1,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  sendPriorityJobNotification,
  updateJobPriority,
} from "../../../../../helpers/apiHelper";
import { resetJobPriorityStateAction } from "../../../actions/jobPriorityActions";
import {
  isPriorityJobADOLinkValid,
  updateADOLinkValidationStatus,
} from "../../../helpers/validateADOLinkHelper";
import { updateResultJob } from "../../../mutators/jobResultMutators";
import { jobPriorityStore } from "../../../store/jobPriorityStore";
import { resultStore } from "../../../store/resultStore";
import { JobSetAsPriorityInputView } from "./JobSetAsPriorityInputView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      flex: 1,
    },
    buttonGroup: {
      alignSelf: "flex-end",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSetAsPriorityDialog = observer(() => {
  const styles = useStyles();
  const toast = useToast();

  const handleSaveAction = async () => {
    toast.onToastStart("Setting job as priority...");
    await updateADOLinkValidationStatus();
    if (isPriorityJobADOLinkValid() === false) {
      toast.onToastFailure("Set job as priority failed: Invalid ADO link");
      return;
    }
    if (jobPriorityStore.currentJobId) {
      updateJobPriority({
        JobId: jobPriorityStore.currentJobId,
        IsPriorityJob: true,
        ADOLink: jobPriorityStore.jobPriorityADOLink,
      })
        .then(
          () => {
            toast.onToastSuccess("Successfully set job as priority");
            const currentJob = resultStore.resultJob;
            if (currentJob) {
              currentJob.IsPriorityJob = true;
              currentJob.PriorityJobADOLink =
                jobPriorityStore.jobPriorityADOLink;
              updateResultJob(currentJob);
            }
            if (jobPriorityStore.managerInfo) {
              sendPriorityJobNotification(jobPriorityStore.managerInfo);
            } else {
              toast.onToastFailure(
                "Failed to send notification: Manager info is missing",
              );
            }
          },
          (error) => {
            toast.onToastFailure(
              `Failed to set job as priority: ${error.message}`,
            );
          },
        )
        .finally(() => {
          resetJobPriorityStateAction();
        });
    } else {
      toast.onToastFailure(`Failed to set job as priority: Job ID is missing`);
      resetJobPriorityStateAction();
    }
  };

  return (
    <Dialog open={jobPriorityStore.isJobSetAsPriorityDialogOpen}>
      <DialogSurface>
        <DialogContent>
          <div className={styles.root}>
            <Subtitle1>Set as priority job</Subtitle1>
            <JobSetAsPriorityInputView />
            <DialogActions className={styles.buttonGroup}>
              <Button appearance="primary" onClick={handleSaveAction}>
                Save
              </Button>
              <Button
                onClick={() => {
                  resetJobPriorityStateAction();
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </DialogSurface>
    </Dialog>
  );
});
