import { Portal, Spinner } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { TemplateType } from "sydneyeval-shared";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { isJobCreationEditDisabled } from "../../../helpers/jobCreationHelper";
import {
  getTemplateType,
  supportEvaluationType,
} from "../../../selectors/getTemplateType";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { JobDataSetView } from "../components/JobDataSetView";
import { JobErrorToast } from "../components/JobErrorToast";
import { JobEvaluationTemplateView } from "../components/JobEvaluationTemplateView";
import { JobEvaluationTypeView } from "../components/JobEvaluationTypeView";
import { JobJsonConfigurationView } from "../components/JobJsonConfigurationView";
import { JobNameView } from "../components/JobNameView";
import { JobPreValidationToast } from "../components/JobPreValidationToast";
import { JobQuickFilterView } from "../components/JobQuickFilterView";
import { JobSetAsPriorityView } from "../components/JobSetAsPriorityView";
import { BizChatFlightReviewRender } from "./BizChatFlightReviewRender";
import { CWCRender } from "./CWCRender";
import { GeneralSkipScrapingRender } from "./GeneralSkipScrapingRender";
import { JsonTemplateRender } from "./JsonTemplateRender";
import { NormalTemplateRender } from "./NormalTemplateRender";
import { OPGTemplateRender } from "./OPGTemplateRender";
import { useJobCreationStyles } from "./useJobCreationStyles";

const renderTemplateByType = () => {
  switch (getTemplateType.get()) {
    case TemplateType.JSON:
      return <JsonTemplateRender />;
    case TemplateType.AIHub:
    case TemplateType.Normal:
      return <NormalTemplateRender />;
    case TemplateType.BingV2:
    case TemplateType.CWC:
      return <CWCRender />;
    case TemplateType.OPG:
      return <OPGTemplateRender />;
    case TemplateType.GeneralSkipScraping:
      return <GeneralSkipScrapingRender />;
    case TemplateType.BizChatFlightReview:
      return <BizChatFlightReviewRender />;
  }
};
export const MainRender = observer(() => {
  const styles = useJobCreationStyles();
  if (jobCreationStore.isLoading) {
    return (
      <div className={styles.headerContainer}>
        <Spinner
          className={styles.spinner}
          labelPosition="below"
          label="Loading Job Templates..."
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {isFeatureEnabled("job-priority") && <JobSetAsPriorityView />}
      <JobQuickFilterView />
      <JobNameView />
      <ResponsiveRow
        maxColumnCount={2}
        maxColumnCountSmall={1}
        rowGap={20}
        columnGap={20}
      >
        <JobEvaluationTemplateView />
        {supportEvaluationType.get() && <JobEvaluationTypeView />}
      </ResponsiveRow>
      <JobDataSetView />
      {renderTemplateByType()}
      <JobJsonConfigurationView />
      <JobErrorToast />
      <JobPreValidationToast />
      <Portal>
        {isJobCreationEditDisabled.get() && <div className={styles.overlay} />}
      </Portal>
    </div>
  );
});
