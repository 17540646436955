import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useResultViewStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    height: "24px",
    fontSize: "18px",
    color: tokens.colorBrandBackground,
    fontWeight: 600,
    fontFamily: "Segoe UI, sans-serif",
    lineHeight: "20px",
    marginBottom: "12px",
  },
  infoCard: {
    backgroundColor: "white",
    width: "fill-available",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    ...shorthands.borderRadius("6px"),
    ...shorthands.padding("20px"),
    marginBottom: "20px",
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    ...shorthands.gap("10px"),
    fontWeight: 400,
  },
  infoTitle: {
    fontWeight: 600,
  },
  detailedCard: {
    backgroundColor: "white",
    width: "fill-available",
    ...shorthands.borderRadius("6px"),
    ...shorthands.margin("0px", "auto"),
    ...shorthands.padding("20px"),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("16px"),
  },
  scenarioRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "flex-start",
    flexGrow: 0,
    ...shorthands.gap("8px"),
    ...shorthands.margin("0px", "0px", "24px", "0px"),
  },
  active: {
    color: "white",
  },
  tab: {
    height: "45px",
    backgroundColor: "transparent",
    position: "relative",
    ...shorthands.border("none"),
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  activeTab: {
    "::after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      right: "0px",
      width: "100%",
      height: "3px",
      backgroundColor: tokens.colorBrandBackground,
      transform: "matrix(1, 0, 0, 1, 0, 0)",
      transformOrigin: "0px 1.5px",
    },
  },
  inactiveTab: {
    ":hover:after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "3px",
      backgroundColor: tokens.colorNeutralStroke1Hover,
      transform: "matrix(1, 0, 0, 1, 0, 0)",
      transformOrigin: "0px 1.5px",
    },
  },
  button: {
    width: "232px",
    height: "44px",
    ...shorthands.padding("6px", "16px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    ...shorthands.borderRadius("6px"),
    ...shorthands.borderWidth("0px"),
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: 600,
    fontSize: "16px",
  },
  dataPane: {
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    ...shorthands.overflow("hidden"),
    ...shorthands.padding("0px", "24px"),
    ...shorthands.gap("16px"),
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableScrollContainer: {
    width: "100%",
    ...shorthands.overflow("auto", "auto"),
  },
  rowWithSmallerGap: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  smallGap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("16px"),
  },
  tableTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  scrollable: {
    overflowX: "auto",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("6px"),
  },
  filterLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: tokens.colorNeutralForeground3,
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  dropdown: {
    float: "right",
    minWidth: "150px",
  },
  bingFileContent: {
    boxsizing: "border-box",
    width: "100%",
    ...shorthands.overflow("auto"),
    ...shorthands.flex(1),
    ...shorthands.padding("10px"),
  },
  dropdownOption: {
    width: "fit-content",
  },
});
