import { mutatorAction } from "satcheljs";
import type { SendPriorityJobNotificationRequestData } from "sydneyeval-shared";
import type { ADOLinkValidationStatus } from "../store/jobPriorityStore";
import { jobPriorityStore } from "../store/jobPriorityStore";

export const updateJobPriorityADOLink = mutatorAction(
  "updateJobPriorityADOLink",
  (newLink: string) => {
    jobPriorityStore.jobPriorityADOLink = newLink;
  },
);

export const updateJobPriorityDescription = mutatorAction(
  "updateJobPriorityDescription",
  (newDescription: string) => {
    jobPriorityStore.jobPriorityDescription = newDescription;
  },
);

export const openJobSetAsPriorityDialog = mutatorAction(
  "openJobSetAsPriorityDialog",
  (isOpen: boolean, currentJobId: number | undefined) => {
    jobPriorityStore.isJobSetAsPriorityDialogOpen = isOpen;
    jobPriorityStore.currentJobId = currentJobId;
  },
);

export const openRemoveJobPriorityDialog = mutatorAction(
  "openRemoveJobPriorityDialog",
  (isOpen: boolean, currentJobId: number | undefined) => {
    jobPriorityStore.isRemoveJobPriorityDialogOpen = isOpen;
    jobPriorityStore.currentJobId = currentJobId;
  },
);

export const resetJobPriorityState = mutatorAction(
  "resetJobPriorityState",
  () => {
    jobPriorityStore.jobPriorityADOLink = "";
    jobPriorityStore.jobPriorityDescription = "";
    jobPriorityStore.isPriorityJob = false;
    jobPriorityStore.isJobSetAsPriorityDialogOpen = false;
    jobPriorityStore.isRemoveJobPriorityDialogOpen = false;
    jobPriorityStore.currentJobId = undefined;
    jobPriorityStore.managerInfo = undefined;
    jobPriorityStore.ADOLinkValidationStatus = undefined;
  },
);

export const updateIsPriorityJob = mutatorAction(
  "updateIsPriorityJob",
  (isPriorityJob: boolean) => {
    jobPriorityStore.isPriorityJob = isPriorityJob;
  },
);

export const updateJobPriorityADOLinkValidationStatus = mutatorAction(
  "updateJobPriorityADOLinkValidationStatus",
  (status: ADOLinkValidationStatus | undefined) => {
    jobPriorityStore.ADOLinkValidationStatus = status;
  },
);

export const updateJobPriorityManagerInfo = mutatorAction(
  "updateJobPriorityManagerInfo",
  (managerInfo: SendPriorityJobNotificationRequestData | undefined) => {
    jobPriorityStore.managerInfo = managerInfo;
  },
);
