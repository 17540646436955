import { Combobox, Input, Label } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  convertLocalDatetimeToUTC,
  datetimeLocalFormat,
} from "../../../helpers/formatHelper";
import { getMatchingOptions } from "../../../helpers/queryManagementHelper";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type {
  ComboboxField,
  ComboboxOptions,
  FormValuesShared,
  SelectOptions,
} from "../types/Display";

type QueryManagementSharedDialogFieldsProps = {
  selectOptions: SelectOptions;
  handleOptionSelect: (
    field: ComboboxField,
    option: string | undefined,
  ) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDatetime: (dateTime: string) => void;
  formValues: FormValuesShared;
  comboboxOptions: ComboboxOptions;
  bulkEdit?: boolean;
};

export const QueryManagementSharedDialogFields = observer(
  (props: QueryManagementSharedDialogFieldsProps) => {
    const {
      selectOptions,
      handleOptionSelect,
      handleChange,
      handleDatetime,
      formValues,
      comboboxOptions,
      bulkEdit,
    } = props;
    const styles = useQueryManagementStyles();

    const renderCombobox = (
      id: keyof ComboboxOptions,
      label: string,
      required = false,
    ) => (
      <div>
        <Label className={styles.formLabel} required={required} htmlFor={id}>
          {label}
        </Label>
        <Combobox
          required={required}
          freeform
          id={id}
          data-testid={id}
          placeholder="Select (or type to create)"
          aria-label={label}
          positioning="below-start"
          value={formValues[id]}
          selectedOptions={comboboxOptions[id]}
          onChange={handleChange}
          onOptionSelect={(_, d) => {
            handleOptionSelect(id, d.optionValue);
          }}
        >
          {getMatchingOptions(
            formValues[id],
            selectOptions[id],
            `Create "${formValues[id]}"`,
          )}
        </Combobox>
      </div>
    );

    return (
      <>
        <div className={styles.formGroup}>
          {renderCombobox("segmentOne", "Segment 1")}
          {renderCombobox("segmentTwo", "Segment 2", !bulkEdit)}
        </div>
        <div className={styles.formGroup}>
          {renderCombobox("environment", "Environment")}
          {renderCombobox("term", "Term")}
        </div>
        <div className={styles.formGroup}>
          <div>
            <Label className={styles.formLabel} htmlFor="queryOwner">
              Query Owner
            </Label>
            <Input
              id="queryOwner"
              aria-label="Query Owner"
              placeholder="Query Owner"
              type="text"
              value={formValues.queryOwner}
              onChange={handleChange}
            />
          </div>
          {renderCombobox("freOrSparklePrompt", "FRE or Sparkle Prompt")}
        </div>
        <div className={styles.formGroup}>
          {renderCombobox("groundingDataSource", "Grounding Data Source")}
          <div>
            <Label className={styles.formLabel} htmlFor="dataSourceInfo">
              Data Source Info
            </Label>
            <Input
              id="dataSourceInfo"
              aria-label="Data Source Info"
              placeholder="Data Source Info"
              type="text"
              value={formValues.dataSourceInfo}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div>
            <Label className={styles.formLabel} htmlFor="timestamp">
              Timestamp
            </Label>
            <Input
              id="timestamp"
              size="medium"
              aria-label="Timestamp"
              placeholder="Timestamp"
              type="datetime-local"
              value={
                formValues.timestamp
                  ? datetimeLocalFormat(formValues.timestamp)
                  : ""
              }
              onChange={(_, data) => {
                if (data?.value === "") {
                  return;
                }
                handleDatetime(convertLocalDatetimeToUTC(data?.value));
              }}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div>
            <Label className={styles.formLabel} htmlFor="userId">
              User ID
            </Label>
            <Input
              id="userId"
              aria-label="User ID"
              placeholder="User ID"
              type="email"
              value={formValues.userId}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    );
  },
);
