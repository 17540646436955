"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConversationHistory = exports.getTheLastQueryInMultiturnMode = exports.multiTurnUtterance = void 0;
const tools_1 = require("../tools");
const parseHelper_1 = require("./parseHelper");
exports.multiTurnUtterance = (0, tools_1.array)((0, tools_1.object)({
    author: tools_1.str,
    text: tools_1.str,
}));
// Create a function to handle the multi-turn query.
// const singleString = "query test";
function getTheLastQueryInMultiturnMode(jsonString) {
    try {
        // jsonString =
        //   '[{"author":"user","text":"high school"},{"author":"bot","text":"test test."},{"author":"user","text":"give me some photo"}]';
        const jsonArray = (0, parseHelper_1.parseJsonStrWithDefault)(jsonString, exports.multiTurnUtterance, []);
        if (Array.isArray(jsonArray) && jsonArray.length > 0) {
            // Return the last element's text which the author is user.
            const lastUserQuery = jsonArray
                .filter((item) => item.author === "user")
                .pop();
            if (lastUserQuery) {
                return lastUserQuery.text;
            }
        }
    }
    catch (error) {
        // Handle the error
        return undefined;
    }
    return undefined;
}
exports.getTheLastQueryInMultiturnMode = getTheLastQueryInMultiturnMode;
function getConversationHistory(jsonString) {
    try {
        // jsonString =
        //   '[{"author":"user","text":"high school"},{"author":"bot","text":"test test."},{"author":"user","text":"give me some photo"}, {"author":"bot","text":"SyndeyReply"}]';
        const jsonArray = (0, parseHelper_1.parseJsonStrWithDefault)(jsonString, exports.multiTurnUtterance, []);
        if (Array.isArray(jsonArray) && jsonArray.length > 0) {
            // If the length of the array is less than 3, the user text is the utterance and the bot text is SyndeyReply, there is no need to display the conversation History.
            if (jsonArray.length < 3) {
                return undefined;
            }
            return JSON.stringify(jsonArray.slice(0, -1));
        }
    }
    catch (error) {
        // Handle the error
        return undefined;
    }
    return undefined;
}
exports.getConversationHistory = getConversationHistory;
