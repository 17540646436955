import { Button } from "@fluentui/react-components";
import { ArrowLeft32Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { submitJobGroupAction } from "../../../actions/jobGroupActions";
import { updateIsDismissDialogOpen } from "../../../mutators/jobGroupCreationMutators";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { useGroupStyles } from "../groupStyles";

export const GroupCreationHeader = observer(() => {
  const styles = useGroupStyles();
  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <Button appearance={"primary"} onClick={submitJobGroupAction}>
          Submit
        </Button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.rowWithSmallGap}>
            <Button
              data-testid="back-button"
              appearance={"subtle"}
              icon={<ArrowLeft32Filled />}
              onClick={() => updateIsDismissDialogOpen(true)}
            ></Button>
            <span className={styles.headerTitle}>Create a new job group</span>
          </div>
        </div>
        <div>
          {!jobGroupCreationStore.isLoading && renderShareSubmitButton()}
        </div>
      </div>
    );
  };
  return <div>{renderHeader()}</div>;
});
