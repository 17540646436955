import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { resetJobPriorityStateAction } from "../../actions/jobPriorityActions";
import { updateIsOpenProductSelectDialog } from "../../mutators/jobCreationMutators";
import { updateJobPermission } from "../../mutators/jobShareMutators";
import { getCurrentSelectedProduct } from "../../selectors/productSelectors";
import { jobCreationStore } from "../../store/jobCreationStore";
import { JobQuickFilterView } from "../JobCreation/components/JobQuickFilterView";

export const ProductSelectDialog = observer(() => {
  const shouldDisableNext = getCurrentSelectedProduct.get() === undefined;

  return (
    <Dialog
      open={jobCreationStore.isOpenProductSelectDialog}
      onOpenChange={() => {
        updateIsOpenProductSelectDialog(false);
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Create new evaluation job</DialogTitle>
          <DialogContent>
            <div>
              {`Please pick the Product Name to setup your evaluation job.`}
            </div>
            <br />
            <JobQuickFilterView />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={shouldDisableNext}
              onClick={() => {
                updateCurrentPath("/create");
                updateJobPermission([]);
                updateIsOpenProductSelectDialog(false);
                resetJobPriorityStateAction();
              }}
              appearance="primary"
            >
              Next
            </Button>
            <Button
              onClick={() => {
                updateIsOpenProductSelectDialog(false);
              }}
              appearance="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
