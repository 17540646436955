import {
  Body1,
  Button,
  Checkbox,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  Dropdown,
  Input,
  Label,
  mergeClasses,
  Option,
  tokens,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import {
  Add12Regular,
  AddCircle24Filled,
  ArrowEnterUp24Filled,
  Delete24Filled,
  Dismiss12Regular,
  Edit24Filled,
  Eye16Regular,
  Tag16Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Tip } from "../../../../../components/Shared/Tip";
import { getRandomUUID } from "../../../../../helpers/getRandomUUID";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import { getMatchingOptions } from "../../../helpers/queryManagementHelper";
import { useSharedTableStyles } from "../../ResultsComparison/Table/shardTableStyles";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type {
  ComboboxOptions,
  FormValues,
  SelectOptions,
} from "../types/Display";
import {
  AssertionProperties,
  AssertionPropertyValues,
  type Assertion,
  type AssertionExample,
  type AssertionProperty,
  type Query,
} from "../types/Query";
import { QueryManagementSharedDialogFields } from "./QueryManagementSharedDialogFields";

type QueryManagementQueryDialogProps = {
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  handleSubmit: (query: Query) => void;
  handleEdit: (query: Query) => void;
  selectOptions: SelectOptions;
  query?: Query;
};

type AssertionValues = {
  id: string;
  text: string;
  level: string;
  owner: string;
  properties: AssertionProperty[];
  examples: AssertionExample[];
  draft: boolean;
};

// TODO: Add ability to edit every field
export const QueryManagementQueryDialog = observer(
  (props: QueryManagementQueryDialogProps) => {
    const styles = useQueryManagementStyles();
    const sharedStyles = useSharedTableStyles();
    const { isOpen, setOpen, handleSubmit, handleEdit, selectOptions, query } =
      props;

    const initialValues: FormValues = {
      id: "",
      query: "",
      segmentOne: "",
      segmentTwo: "",
      queryOwner: "",
      environment: "",
      term: "",
      freOrSparklePrompt: "",
      groundingDataSource: "",
      dataSourceInfo: "",
      timestamp: "",
      userId: "",
      sets: [],
      assertions: [],
      tags: [], // TODO: Implement way to edit assertion tags
      annotations: [],
      draft: false,
      edited: false,
    };

    const initialSelectedOptions: ComboboxOptions = {
      segmentOne: [],
      segmentTwo: [],
      environment: [],
      term: [],
      freOrSparklePrompt: [],
      groundingDataSource: [],
    };

    const [originalFormValues, setOriginalFormValues] =
      useState<FormValues>(initialValues);

    const [formValues, setFormValues] = useState<FormValues>(initialValues);
    const [comboboxOptions, setFormSelectedOptions] = useState<ComboboxOptions>(
      initialSelectedOptions,
    );

    const [setsFieldValue, setSetsFieldValue] = useState<string>("");
    const [selectedSets, setSelectedSets] = useState<string[]>([]);

    const [addingAssertion, setAddingAssertion] = useState<boolean>(false);
    const [newAssertionValues, setNewAssertionValues] =
      useState<AssertionValues>({
        id: "",
        text: "",
        level: "",
        owner: "",
        properties: [],
        examples: [],
        draft: false,
      });

    const [assertionPropertyValue, setAssertionPropertyValue] =
      useState<string>("");

    const [assertionEdit, setAssertionEdit] = useState<string>("");

    useEffect(() => {
      if (isOpen) {
        if (query) {
          const queryProps = getQueryProps(query);
          setOriginalFormValues(queryProps);
          setFormValues(queryProps);
          setFormSelectedOptions({
            segmentOne: query.segmentOne ? [query.segmentOne] : [],
            segmentTwo: query.segmentTwo ? [query.segmentTwo] : [],
            environment: query.environment ? [query.environment] : [],
            term: query.term ? [query.term] : [],
            freOrSparklePrompt: query.freOrSparklePrompt
              ? [query.freOrSparklePrompt]
              : [],
            groundingDataSource: query.groundingDataSource
              ? [query.groundingDataSource]
              : [],
          });
          setSelectedSets(query.sets ?? []);
        } else {
          setFormValues(initialValues);
          setFormSelectedOptions(initialSelectedOptions);
        }
      }
    }, [isOpen, query]);

    useEffect(() => {
      if (newAssertionValues.properties !== undefined) {
        setAssertionPropertyValue(newAssertionValues.properties.join(", "));
      }
    }, [newAssertionValues.properties]);

    useEffect(() => {
      setSelectedSets(formValues.sets ?? []);
    }, [formValues.sets]);

    const isEdited = (newFormValues: FormValues) => {
      const restNewInit: Record<string, unknown> = {};
      const restNew = Object.entries(newFormValues).reduce(
        (acc, [key, val]) => {
          if (key !== "edited") acc[key] = val;
          return acc;
        },
        restNewInit,
      );

      const restOriginalInit: Record<string, unknown> = {};
      const restOriginal = Object.entries(originalFormValues).reduce(
        (acc, [key, val]) => {
          if (key !== "edited") acc[key] = val;
          return acc;
        },
        restOriginalInit,
      );

      if ("sets" in restNew && Array.isArray(restNew.sets)) {
        restNew.sets?.sort();
      }

      if ("sets" in restOriginal && Array.isArray(restOriginal.sets)) {
        restOriginal.sets?.sort();
      }

      return JSON.stringify(restNew) !== JSON.stringify(restOriginal);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const field = event.target.id;

      const textOnlyFields = [
        "query",
        "queryOwner",
        "dataSourceInfo",
        "userId",
      ];

      if (!textOnlyFields.includes(field)) {
        if (value.length === 0) {
          setFormSelectedOptions({
            ...comboboxOptions,
            [field]: [],
          });
        }
      }

      setFormValues((prev) => {
        const newFormValues = {
          ...prev,
          [field]: value,
        };

        return {
          ...newFormValues,
          edited: isEdited(newFormValues),
        };
      });
    };

    const handleDatetime = (value: string) => {
      setFormValues({
        ...formValues,
        timestamp: value,
      });
    };

    const updateFormField = (field: string, value: string | string[]) => {
      setFormValues((prev) => {
        const newFormValues = {
          ...prev,
          [field]: value,
        };

        return {
          ...newFormValues,
          edited: isEdited(newFormValues),
        };
      });
    };

    const handleOptionSelect = (
      field:
        | "segmentOne"
        | "segmentTwo"
        | "environment"
        | "term"
        | "groundingDataSource"
        | "freOrSparklePrompt",
      option: string | undefined,
    ) => {
      if (option === undefined) {
        return;
      }

      if (option === "") {
        option = formValues[field];
      }

      updateFormField(field, option);
      setFormSelectedOptions({
        ...comboboxOptions,
        [field]: [option],
      });
    };

    const handleSetsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSetsFieldValue(event.target.value);
    };

    const handleSetSelect = (set: string | undefined) => {
      if (set === undefined) {
        return;
      }

      if (set === "") {
        set = setsFieldValue;
      }

      let newSets: string[];
      if (formValues.sets?.includes(set)) {
        newSets = formValues.sets.filter((value) => value !== set);
      } else {
        if (formValues.sets === undefined) {
          newSets = [set];
        } else {
          newSets = [...formValues.sets, set];
        }
      }

      updateFormField("sets", newSets);
      setSetsFieldValue("");
    };

    const invalidValues = () =>
      formValues.query === "" ||
      formValues.segmentTwo === "" ||
      updatingAssertions;

    const resetNewAssertionValues = () =>
      setNewAssertionValues({
        id: "",
        text: "",
        level: "",
        owner: "",
        properties: [],
        examples: [],
        draft: false,
      });

    const resetForm = () => {
      setFormValues(initialValues);
      setFormSelectedOptions(initialSelectedOptions);
      setSetsFieldValue("");
      setAddingAssertion(false);
      resetNewAssertionValues();
    };

    const onTagClick = (option: string) => {
      setFormValues((prev) => {
        const newFormValues = {
          ...prev,
          sets: prev.sets?.filter((tag) => tag !== option),
        };

        return {
          ...newFormValues,
          edited: isEdited(newFormValues),
        };
      });
    };

    const headerCell = mergeClasses(sharedStyles.cell, styles.headerCell);
    const assertionCell = mergeClasses(sharedStyles.cell, styles.assertionCell);

    const renderEditButton = (assertion: Assertion) => (
      <Button
        aria-label="Edit Assertion"
        appearance="transparent"
        icon={<Edit24Filled />}
        onClick={(event) => {
          setAssertionEdit(assertion.id ?? "");
          setNewAssertionValues({
            id: assertion.id ?? getRandomUUID(),
            text: assertion.text,
            level: assertion.level ?? "",
            owner: assertion.owner ?? "",
            properties: assertion.properties ? [...assertion.properties] : [],
            examples: assertion.examples ? [...assertion.examples] : [],
            draft: assertion.draft ?? false,
          });
          event?.stopPropagation();
        }}
      />
    );

    const renderDeleteButton = (id: string | undefined) => {
      if (id === undefined) {
        return null;
      }

      return (
        <Button
          aria-label="Delete Assertion"
          appearance="transparent"
          icon={<Delete24Filled />}
          onClick={(event) => {
            deleteAssertion(id);
            event?.stopPropagation();
          }}
        />
      );
    };

    const [updatingAssertions, setUpdatingAssertions] = useState<boolean>(
      addingAssertion || assertionEdit !== "",
    );
    const [emptyAssertionText, setEmptyAssertionText] = useState<boolean>(
      newAssertionValues.text === "",
    );
    const [addAssertionDisabled, setAddAssertionDisabled] = useState<boolean>(
      addingAssertion && emptyAssertionText,
    );
    const [editAssertionDisabled, setEditAssertionDisabled] = useState<boolean>(
      assertionEdit !== "" && emptyAssertionText,
    );

    useEffect(() => {
      setUpdatingAssertions(addingAssertion || assertionEdit !== "");
    }, [addingAssertion, assertionEdit]);

    useEffect(() => {
      setEmptyAssertionText(newAssertionValues.text === "");
    }, [newAssertionValues]);

    useEffect(() => {
      setAddAssertionDisabled(addingAssertion && emptyAssertionText);
    }, [addingAssertion, emptyAssertionText]);

    useEffect(() => {
      setEditAssertionDisabled(assertionEdit !== "" && emptyAssertionText);
    }, [assertionEdit, emptyAssertionText]);

    const addNewAssertion = () => {
      const prev = formValues.assertions ?? [];
      setFormValues({
        ...formValues,
        assertions: [
          ...prev,
          {
            id: getRandomUUID(),
            text: newAssertionValues.text,
            level: newAssertionValues.level,
            owner: newAssertionValues.owner,
            examples: newAssertionValues.examples,
            properties: newAssertionValues.properties,
            draft: newAssertionValues.draft,
          },
        ],
        edited: true,
      });
      resetNewAssertionValues();
      telemetryHelper.logUserActionEvent(
        "QueryManagementToolCreateNewAssertion",
      );
    };

    const editAssertion = () => {
      setFormValues((prev) => {
        const prevAssertions = prev.assertions ?? [];
        const newFormValues = {
          ...prev,
          assertions: prevAssertions.map((assertion) =>
            assertion.id === assertionEdit
              ? {
                  id: newAssertionValues.id,
                  text: newAssertionValues.text,
                  level: newAssertionValues.level,
                  owner: newAssertionValues.owner,
                  examples: newAssertionValues.examples,
                  properties: newAssertionValues.properties,
                  draft: newAssertionValues.draft,
                }
              : assertion,
          ),
        };

        return {
          ...newFormValues,
          edited: isEdited(newFormValues),
        };
      });

      resetNewAssertionValues();
      telemetryHelper.logUserActionEvent("QueryManagementToolEditAssertion");
    };

    const deleteAssertion = (id: string) => {
      setFormValues((prev) => {
        const prevAssertions = prev.assertions ?? [];
        const newFormValues = {
          ...prev,
          assertions: prevAssertions.filter((assertion) => assertion.id !== id),
        };

        return {
          ...newFormValues,
          edited: isEdited(newFormValues),
        };
      });

      telemetryHelper.logUserActionEvent("QueryManagementToolDeleteAssertion");
    };

    const handleAssertionsUpdateButtonClick = () => {
      if (addingAssertion) {
        addNewAssertion();
        setAddingAssertion(false);
      } else if (assertionEdit) {
        editAssertion();
        setAssertionEdit("");
      } else {
        setAddingAssertion(true);
      }
    };

    const renderAssertionExamplesDialog = (assertion: Assertion) => {
      const editing = assertionEdit === assertion.id;
      const adding = addingAssertion && assertion.id === "";
      return (
        <Dialog>
          <DialogTrigger>
            <Button
              icon={<Eye16Regular />}
              className={styles.assertionExamplesButton}
              disabled={
                (addingAssertion || assertionEdit !== "") && !editing && !adding
              }
            >
              Examples
            </Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Assertion examples</DialogTitle>
              <DialogContent>
                <Body1>
                  <span className={styles.boldLabel}>Assertion:</span>{" "}
                  {assertion.text}
                </Body1>
                <Tree style={{ marginTop: "6px" }}>
                  {assertion.examples?.map((example, index) => (
                    <TreeItem key={index} itemType="branch">
                      <TreeItemLayout
                        actions={
                          adding || editing
                            ? [
                                <Button
                                  key={`button-${index}`}
                                  aria-label={`Delete example ${index + 1}`}
                                  icon={
                                    <Delete24Filled
                                      color={tokens.colorBrandBackground}
                                    />
                                  }
                                  onClick={(event) => {
                                    const examples =
                                      newAssertionValues.examples.filter(
                                        (_, i) => i !== index,
                                      );
                                    setNewAssertionValues({
                                      ...newAssertionValues,
                                      examples: examples,
                                    });
                                    event.stopPropagation();
                                  }}
                                />,
                              ]
                            : []
                        }
                      >
                        Example {index + 1}
                      </TreeItemLayout>
                      <Tree>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Response:</span>{" "}
                            {adding || editing ? (
                              <Input
                                id={`exampleResponse-${assertion.id}-${index}`}
                                aria-label="Example response"
                                placeholder="Example response"
                                type="text"
                                appearance="underline"
                                value={example.response}
                                onChange={(event) => {
                                  const examples =
                                    newAssertionValues.examples.map((ex, i) =>
                                      i === index
                                        ? {
                                            ...ex,
                                            response: event.target.value,
                                          }
                                        : ex,
                                    );
                                  setNewAssertionValues({
                                    ...newAssertionValues,
                                    examples: examples,
                                  });
                                }}
                                onClick={(event) => event.stopPropagation()}
                              />
                            ) : (
                              example.response
                            )}
                          </TreeItemLayout>
                        </TreeItem>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Score:</span>{" "}
                            {adding || editing ? (
                              <Input
                                id={`exampleScore-${assertion.id}-${index}`}
                                aria-label="Example score"
                                placeholder="Example score"
                                type="number"
                                appearance="underline"
                                min={0}
                                max={2}
                                value={example.score.toString()}
                                onChange={(event) => {
                                  const examples =
                                    newAssertionValues.examples.map((ex, i) =>
                                      i === index
                                        ? {
                                            ...ex,
                                            score: parseInt(event.target.value),
                                          }
                                        : ex,
                                    );
                                  setNewAssertionValues({
                                    ...newAssertionValues,
                                    examples: examples,
                                  });
                                }}
                                onClick={(event) => event.stopPropagation()}
                              />
                            ) : (
                              example.score
                            )}
                          </TreeItemLayout>
                        </TreeItem>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Rationale:</span>{" "}
                            {adding || editing ? (
                              <Input
                                id={`exampleRationale-${assertion.id}-${index}`}
                                aria-label="Example rationale"
                                placeholder="Example rationale"
                                type="text"
                                appearance="underline"
                                value={example.rationale}
                                onChange={(event) => {
                                  const examples =
                                    newAssertionValues.examples.map((ex, i) =>
                                      i === index
                                        ? {
                                            ...ex,
                                            rationale: event.target.value,
                                          }
                                        : ex,
                                    );
                                  setNewAssertionValues({
                                    ...newAssertionValues,
                                    examples: examples,
                                  });
                                }}
                                onClick={(event) => event.stopPropagation()}
                              />
                            ) : (
                              example.rationale
                            )}
                          </TreeItemLayout>
                        </TreeItem>
                      </Tree>
                    </TreeItem>
                  ))}
                  {(adding || editing) && (
                    <>
                      <Divider
                        style={{ marginTop: "8px", marginBottom: "8px" }}
                      />
                      <Button
                        style={{
                          width: "fit-content",
                          fontWeight: 400,
                        }}
                        appearance="subtle"
                        icon={<Add12Regular />}
                        onClick={() => {
                          const examples = [
                            ...(newAssertionValues.examples || []),
                            {
                              response: "",
                              score: 0,
                              rationale: "",
                            },
                          ];
                          setNewAssertionValues({
                            ...newAssertionValues,
                            examples: examples,
                          });
                        }}
                      >
                        Add Example
                      </Button>
                    </>
                  )}
                </Tree>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Close</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      );
    };

    const renderAssertionEditFields = () => (
      <>
        <td className={assertionCell}>
          <Input
            className={styles.assertionEditField}
            id="assertionText"
            aria-label="Assertion text"
            placeholder="Assertion text"
            type="text"
            appearance="underline"
            value={newAssertionValues.text}
            onChange={(event) => {
              setNewAssertionValues({
                ...newAssertionValues,
                text: event.target.value,
              });
            }}
          />
        </td>
        <td className={sharedStyles.cell}>
          <Dropdown
            className={styles.assertionEditField}
            id="assertionLevel"
            placeholder="Select assertion level"
            aria-label="Assertion Level"
            appearance="underline"
            value={newAssertionValues.level}
            selectedOptions={
              newAssertionValues.level ? [newAssertionValues.level] : []
            }
            onOptionSelect={(_, option) => {
              setNewAssertionValues({
                ...newAssertionValues,
                level: option.optionValue ?? "",
              });
            }}
          >
            <Option value="critical">critical</Option>
            <Option value="expected">expected</Option>
            <Option value="aspirational">aspirational</Option>
          </Dropdown>
        </td>
        <td className={sharedStyles.cell}>
          <Input
            className={styles.assertionEditField}
            id="assertionOwners"
            aria-label="Assertion owner(s)"
            placeholder="Assertion owner(s)"
            type="text"
            appearance="underline"
            value={newAssertionValues.owner}
            onChange={(event) => {
              setNewAssertionValues({
                ...newAssertionValues,
                owner: event.target.value,
              });
            }}
          />
        </td>
        <td className={sharedStyles.cell}>
          <Dropdown
            multiselect
            className={styles.assertionEditField}
            id="assertionProperties"
            placeholder="Select assertion properties"
            aria-label="Assertion Properties"
            appearance="underline"
            value={assertionPropertyValue}
            selectedOptions={newAssertionValues.properties}
            onOptionSelect={(_, data) => {
              setNewAssertionValues({
                ...newAssertionValues,
                properties: AssertionProperties(data.selectedOptions, ""),
              });
            }}
          >
            {AssertionPropertyValues.map((prop, index) => (
              <Option key={index} value={prop}>
                {prop}
              </Option>
            ))}
          </Dropdown>
        </td>
        <td className={sharedStyles.cell}>
          {renderAssertionExamplesDialog(newAssertionValues)}
        </td>
        <td className={sharedStyles.cell}>
          {renderAssertionDraftCheckbox(newAssertionValues)}
        </td>
      </>
    );

    const renderAssertionDraftCheckbox = (assertion: Assertion) => {
      const editing = assertionEdit === assertion.id;
      const adding = addingAssertion && assertion.id === "";
      return editing || adding ? (
        <Checkbox
          checked={newAssertionValues.draft}
          onChange={() => {
            setNewAssertionValues({
              ...newAssertionValues,
              draft: !newAssertionValues.draft,
            });
          }}
        />
      ) : (
        <Checkbox checked={assertion.draft ?? false} disabled />
      );
    };

    const renderAssertionsTable = () => (
      <table className={sharedStyles.table}>
        <thead className={sharedStyles.thead}>
          <tr className={sharedStyles.rowStyle}>
            <th className={headerCell}>Assertion</th>
            <th className={headerCell}>Level</th>
            <th className={headerCell}>Owner(s)</th>
            <th className={headerCell}>Properties</th>
            <th className={headerCell}>Examples</th>
            <th className={headerCell}>Draft?</th>
          </tr>
        </thead>
        <tbody className={sharedStyles.tbody}>
          {formValues.assertions &&
            formValues.assertions.map((assertion, index) => {
              return (
                <tr key={index} className={styles.rowStyle}>
                  {assertionEdit === assertion.id ? (
                    renderAssertionEditFields()
                  ) : (
                    <>
                      <td className={assertionCell}>
                        <div
                          className={mergeClasses(
                            styles.assertionText,
                            sharedStyles.longTextWrapper,
                          )}
                        >
                          <Tip
                            withArrow
                            content={assertion.text}
                            relationship="label"
                          >
                            <Body1
                              className={sharedStyles.longText}
                              style={{ marginLeft: "8px" }}
                            >
                              {assertion.text}
                            </Body1>
                          </Tip>
                          {!updatingAssertions && !assertion.fromTag && (
                            <div>
                              {renderEditButton(assertion)}
                              {renderDeleteButton(assertion.id)}
                            </div>
                          )}
                          {assertion.fromTag && (
                            <div>
                              <Tip
                                content={`This assertion is from tag ${assertion.fromTag} and currently cannot be edited. Please export and manually edit tagged assertions for now.`}
                                relationship="label"
                              >
                                <div>
                                  <Tag16Regular
                                    style={{
                                      display: "inline-flex",
                                      color: tokens.colorBrandBackground,
                                    }}
                                  />
                                </div>
                              </Tip>
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        className={sharedStyles.cell}
                        style={{ paddingLeft: "8px" }}
                      >
                        {assertion.level}
                      </td>
                      <td
                        className={sharedStyles.cell}
                        style={{ paddingLeft: "8px" }}
                      >
                        {assertion.owner}
                      </td>
                      <td className={sharedStyles.cell}>
                        {assertion.properties?.map((prop, i) => (
                          <Tip key={i} content={prop} relationship="label">
                            <div className={styles.assertionProp}>{prop}</div>
                          </Tip>
                        ))}
                      </td>
                      <td className={sharedStyles.cell}>
                        {renderAssertionExamplesDialog(assertion)}
                      </td>
                      <td className={sharedStyles.cell}>
                        {renderAssertionDraftCheckbox(assertion)}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          {addingAssertion && (
            <tr className={styles.rowStyle}>{renderAssertionEditFields()}</tr>
          )}
        </tbody>
      </table>
    );

    const handleFormSubmit = () => {
      if (query) {
        handleEdit({
          ...query,
          ...formValues,
        });
      } else {
        handleSubmit({
          ...formValues,
          id: getRandomUUID(),
        });
      }
      resetForm();
    };

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, { open }) => {
          setOpen(open);
          if (!open) {
            resetForm();
            setAssertionEdit("");
            setAddingAssertion(false);
          }
        }}
      >
        <DialogSurface className={styles.queryDialog}>
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              handleFormSubmit();
            }}
          >
            <DialogBody>
              <DialogTitle>
                {query ? "Edit Query" : "Add New Query"}
              </DialogTitle>
              <DialogContent className={styles.form}>
                <div className={styles.formLabel}>
                  <span className={styles.requiredSymbol}>*</span> = Required
                </div>
                <Label className={styles.formLabel} required htmlFor="query">
                  Query
                </Label>
                <Input
                  id="query"
                  aria-label="Query"
                  placeholder="Query"
                  type="text"
                  value={formValues.query}
                  required
                  onChange={handleChange}
                />
                <QueryManagementSharedDialogFields
                  selectOptions={selectOptions}
                  handleOptionSelect={handleOptionSelect}
                  handleChange={handleChange}
                  formValues={formValues}
                  comboboxOptions={comboboxOptions}
                  handleDatetime={handleDatetime}
                />
                <Label className={styles.formLabel} htmlFor="sets">
                  Sets
                </Label>
                {selectedSets.length ? (
                  <ul className={styles.tagsList}>
                    {selectedSets.map((set) => (
                      <li key={set}>
                        <Button
                          size="small"
                          shape="circular"
                          appearance="primary"
                          icon={<Dismiss12Regular />}
                          iconPosition="after"
                          onClick={() => onTagClick(set)}
                        >
                          {set}
                        </Button>
                      </li>
                    ))}
                  </ul>
                ) : null}
                <Combobox
                  freeform
                  multiselect
                  id="sets"
                  data-testid="sets"
                  placeholder="Select multiple (or type to create)"
                  aria-label="Sets"
                  positioning="below-start"
                  value={setsFieldValue}
                  selectedOptions={selectedSets}
                  onChange={handleSetsChange}
                  onOptionSelect={(_, d) => handleSetSelect(d.optionValue)}
                >
                  {getMatchingOptions(
                    setsFieldValue,
                    selectOptions.sets,
                    `Create "${setsFieldValue}"`,
                  )}
                </Combobox>
                <Divider appearance="strong" />
                <Label className={styles.formLabel}>Assertions</Label>
                {!addingAssertion && !formValues.assertions && (
                  <div>No assertions.</div>
                )}
                {(addingAssertion ||
                  (formValues.assertions &&
                    formValues.assertions.length > 0)) &&
                  renderAssertionsTable()}
                <div>
                  <Button
                    aria-label={updatingAssertions ? "Save" : "New Assertion"}
                    className={styles.assertionActionButton}
                    appearance={updatingAssertions ? "primary" : "secondary"}
                    icon={
                      updatingAssertions ? (
                        <ArrowEnterUp24Filled />
                      ) : (
                        <AddCircle24Filled className={styles.addIcon} />
                      )
                    }
                    disabled={addAssertionDisabled || editAssertionDisabled}
                    onClick={handleAssertionsUpdateButtonClick}
                  >
                    {updatingAssertions ? "Save" : "New Assertion"}
                  </Button>
                  {updatingAssertions && (
                    <Button
                      className={styles.cancelButton}
                      aria-label="Cancel"
                      appearance="subtle"
                      onClick={() => {
                        addingAssertion
                          ? setAddingAssertion(false)
                          : setAssertionEdit("");

                        resetNewAssertionValues();
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </DialogContent>
              <DialogActions className={styles.dialogActions}>
                {formValues.edited && (
                  <Label className={styles.formLabel}>Edited</Label>
                )}
                <Checkbox
                  size="large"
                  checked={formValues.draft}
                  onChange={(event) => {
                    setFormValues((prev) => {
                      const newFormValues = {
                        ...prev,
                        draft: event.target.checked,
                      };

                      return {
                        ...newFormValues,
                        edited: isEdited(newFormValues),
                      };
                    });
                  }}
                  label="Draft"
                />
                <DialogTrigger>
                  <Button appearance="secondary">Close</Button>
                </DialogTrigger>
                <Button
                  appearance="primary"
                  type="submit"
                  disabled={invalidValues()}
                >
                  Submit
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    );
  },
);

export const getQueryProps = (q: Query): FormValues => ({
  id: q.id,
  query: q.query,
  segmentOne: q.segmentOne ?? "",
  segmentTwo: q.segmentTwo ?? "",
  queryOwner: q.queryOwner ?? "",
  environment: q.environment ?? "",
  term: q.term ?? "",
  freOrSparklePrompt: q.freOrSparklePrompt ?? "",
  groundingDataSource: q.groundingDataSource ?? "",
  dataSourceInfo: q.dataSourceInfo ?? "",
  timestamp: q.timestamp ?? "",
  userId: q.userId ?? "",
  sets: q.sets ?? [],
  assertions: q.assertions ?? [],
  tags: q.tags ?? [],
  annotations: q.annotations ?? [],
  draft: q.draft ?? false,
  edited: false,
});
