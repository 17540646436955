import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  makeStyles,
  Option,
  Radio,
  shorthands,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tag,
  TagGroup,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { CloseIcon } from "../../../../components/Shared/Icons";
import { getSyntheticVirtualTenants } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import type {
  VirtualAccount,
  VirtualTenant,
} from "../../../../models/VirtualTenant";
import {
  setOpenItems,
  setSearchCreator,
  setSearchRing,
  setSearchTag,
  setSearchTenant,
  setSelectedUser,
  setSelectedVirtualAccount,
  setSelectedVirtualTenant,
  setVirtualTenants,
  toggleDialogOpen,
} from "../../mutators/jobCreationSyntheticMutators";
import { jobCreationSyntheticStore } from "../../store/jobCreationSyntheticStore";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    borderRadius: "8px",
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    backgroundColor: "#FAFAFA",
  },
  dialogSurface: {
    width: "1050px",
    height: "80vh",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    textAlign: "start",
    width: "100%",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  dialogBody: {
    marginBottom: "16px",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    overflowY: "auto",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  searchArea: {
    display: "flex",
    marginTop: "25px",
    marginBottom: "15px",
    flexDirection: "row",
    gap: "10px",
  },
  searchField: {
    flex: "1",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    gap: "4px",
  },
  searchInput: {
    flex: "1",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  userTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  closeButton: {
    cursor: "pointer",
  },
  tableHeader: {
    borderBottom: "none",
    backgroundColor: "#FAFAFA",
    pointerEvents: "none",
  },
  tableCell: {
    textAlign: "start",
    justifyContent: "flex-start",
  },
  tableHeaderCell: {
    textAlign: "start",
    fontWeight: "bold",
  },
  tableHeaderNamePaddingCell: {
    textAlign: "start",
    fontWeight: "bold",
    paddingLeft: "46px",
  },
  tableNamePaddingCell: {
    textAlign: "start",
    paddingLeft: "26px",
  },
  tableTagPaddingCell: {
    textAlign: "start",
    fontWeight: "bold",
    paddingLeft: "26px",
  },
  selectedBackground: {
    backgroundColor: "#EBEFFF",
    ":hover": {
      backgroundColor: "#EBEFFF",
    },
  },
  tagsGroup: {
    display: "flex",
    justifyContent: "flex-start",
  },
  accordionHeader: {
    backgroundColor: "white",
  },
  tableRow: {
    pointerEvents: "none",
  },
  tableItemRow: {
    backgroundColor: "#FAFAFA",
  },
  noContent: {
    textAlign: "center",
    margin: "16px",
  },
});

type ITenantAccordionProps = {
  filteredTenants: VirtualTenant[];
};

type JobSelectSyntheticUserDialogProps = {
  onUserSelected: () => void;
};

export const TenantAccordion = observer((props: ITenantAccordionProps) => {
  const { filteredTenants } = props;
  const styles = useStyles();

  return (
    <Accordion
      multiple
      collapsible
      openItems={jobCreationSyntheticStore.openItems}
      onToggle={(_, data) => {
        const { value } = data as { value: string };
        const prevOpenItems: string[] = jobCreationSyntheticStore.openItems;
        setOpenItems(
          prevOpenItems.includes(value)
            ? prevOpenItems.filter((item) => item !== value)
            : [...prevOpenItems, value],
        );
      }}
    >
      {filteredTenants.map((tenant) => (
        <AccordionItem value={tenant.tenantId} key={tenant.tenantId}>
          <AccordionHeader
            className={
              tenant.tenantId ==
              jobCreationSyntheticStore.selectedVirtualTenant?.tenantId
                ? styles.selectedBackground
                : styles.accordionHeader
            }
          >
            <Table>
              <TableBody>
                <TableRow className={styles.tableRow}>
                  <TableCell colSpan={2} className={styles.tableCell}>
                    {tenant.tenantName}
                  </TableCell>
                  <TableCell colSpan={2} className={styles.tableCell}>
                    <TagGroup className={styles.tagsGroup}>
                      {tenant.tenantTags.map((tag, index) => (
                        <Tag key={index}>{tag}</Tag>
                      ))}
                    </TagGroup>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {tenant.ring ?? "WW"}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {tenant.createdBy.includes("@")
                      ? tenant.createdBy.split("@")[0]
                      : tenant.createdBy}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionHeader>
          <AccordionPanel>
            <Table className={styles.userTable}>
              <TableBody>
                {tenant.physicalTenants
                  .flatMap((pt) => pt.virtualAccounts)
                  .reduce<VirtualAccount[]>((acc, item) => {
                    if (!acc.some((va) => va.userId === item.userId)) {
                      acc.push(item);
                    }
                    return acc;
                  }, [])
                  .filter((va) => {
                    return (
                      tenant.tenantName
                        .toLowerCase()
                        .includes(
                          (
                            jobCreationSyntheticStore.searchTenant || ""
                          ).toLowerCase(),
                        ) ||
                      va.userName
                        .toLowerCase()
                        .includes(
                          (
                            jobCreationSyntheticStore.searchTenant || ""
                          ).toLowerCase(),
                        )
                    );
                  })
                  .map((va) => (
                    <TableRow
                      key={va.userId}
                      className={
                        va.userId ==
                          jobCreationSyntheticStore.selectedVirtualAccount
                            ?.userId &&
                        tenant.tenantId ==
                          jobCreationSyntheticStore.selectedVirtualTenant
                            ?.tenantId
                          ? styles.selectedBackground
                          : styles.tableItemRow
                      }
                      onClick={() => {
                        setSelectedVirtualAccount(va);
                        setSelectedVirtualTenant(tenant);
                      }}
                    >
                      <TableCell
                        colSpan={2}
                        className={styles.tableNamePaddingCell}
                      >
                        <Radio
                          label={va.userName}
                          checked={
                            va.userId ==
                              jobCreationSyntheticStore.selectedVirtualAccount
                                ?.userId &&
                            tenant.tenantId ==
                              jobCreationSyntheticStore.selectedVirtualTenant
                                ?.tenantId
                          }
                        />
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        className={styles.tableTagPaddingCell}
                      >
                        <TagGroup>
                          {va.userTags.map((tag, index) => (
                            <Tag key={index}>{tag}</Tag>
                          ))}
                        </TagGroup>
                      </TableCell>
                      <TableCell className={styles.tableCell} />
                      <TableCell className={styles.tableCell} />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
});

const JobSelectSyntheticUserDialog = observer(
  (props: JobSelectSyntheticUserDialogProps) => {
    const { onUserSelected } = props;
    const styles = useStyles();

    useEffect(() => {
      const fetchVirtualTenants = () => {
        perfWrapper(
          "LoadSyntheticVirtualTenantList",
          Promise.resolve().then(() =>
            Promise.all([getSyntheticVirtualTenants()]).then(
              ([virtualTenants]) => {
                setVirtualTenants(virtualTenants);
              },
            ),
          ),
        );
      };

      fetchVirtualTenants();
    }, []);

    const filteredAccounts = useMemo(
      () =>
        (jobCreationSyntheticStore.virtualTenants || [])
          .flatMap((vt) => vt.physicalTenants)
          .flatMap((pt) => pt.virtualAccounts)
          .reduce<VirtualAccount[]>((acc, item) => {
            if (!acc.some((va) => va.userId === item.userId)) {
              acc.push(item);
            }
            return acc;
          }, [])
          .filter((va) => {
            return va.userName
              .toLowerCase()
              .includes(
                (jobCreationSyntheticStore.searchTenant || "").toLowerCase(),
              );
          }),
      [
        jobCreationSyntheticStore.virtualTenants,
        jobCreationSyntheticStore.searchTenant,
      ],
    );

    const filteredTenants = useMemo(
      () =>
        (jobCreationSyntheticStore.virtualTenants || []).filter((tenant) => {
          const tenantMatches = tenant.tenantName
            .toLowerCase()
            .includes(
              (jobCreationSyntheticStore.searchTenant || "").toLowerCase(),
            );
          const accountMatches = filteredAccounts.some((va) =>
            tenant.physicalTenants.some((pt) =>
              pt.virtualAccounts.some(
                (account) => account.userId === va.userId,
              ),
            ),
          );
          const tagMatches = tenant.tenantTags.some((tag) =>
            tag
              .toLowerCase()
              .includes(
                (jobCreationSyntheticStore.searchTag || "").toLowerCase(),
              ),
          );
          const ringMatches =
            !tenant.ring ||
            tenant.ring
              ?.toLowerCase()
              ?.includes(
                (jobCreationSyntheticStore.searchRing || "").toLowerCase(),
              );
          const creatorMatches = tenant.createdBy
            .toLowerCase()
            .includes(
              (jobCreationSyntheticStore.searchCreator || "").toLowerCase(),
            );
          return (
            (tenantMatches || accountMatches) &&
            tagMatches &&
            ringMatches &&
            creatorMatches
          );
        }),
      [
        jobCreationSyntheticStore.virtualTenants,
        jobCreationSyntheticStore.searchTenant,
        jobCreationSyntheticStore.searchTag,
        jobCreationSyntheticStore.searchRing,
        jobCreationSyntheticStore.searchCreator,
        filteredAccounts,
      ],
    );

    const saveUserSelection = () => {
      const virtualTenant = jobCreationSyntheticStore.selectedVirtualTenant;
      const virtualAccount = jobCreationSyntheticStore.selectedVirtualAccount;
      if (virtualTenant === undefined || virtualAccount === undefined) {
        return;
      }

      const physicalTenantCandidates = virtualTenant.physicalTenants.filter(
        (physicalTenant) =>
          physicalTenant.virtualAccounts.some(
            (account) => account.userName === virtualAccount.userName,
          ),
      );

      if (physicalTenantCandidates.length === 0) {
        return;
      }

      const selectedPhysicalTenant =
        physicalTenantCandidates[
          Math.floor(Math.random() * physicalTenantCandidates.length)
        ];

      const physicalAccountCandidates =
        selectedPhysicalTenant.virtualAccounts.find(
          (account) => account.userName === virtualAccount.userName,
        )?.physicalAccounts;

      if (
        !physicalAccountCandidates ||
        physicalAccountCandidates.length === 0
      ) {
        return;
      }

      const selectedPhysicalAccount =
        physicalAccountCandidates[
          Math.floor(Math.random() * physicalAccountCandidates.length)
        ];

      if (!selectedPhysicalAccount) {
        return;
      }

      const user = `${selectedPhysicalAccount.userName} ${selectedPhysicalTenant.tenantName}`;
      setSelectedUser(user);
      onUserSelected();
      toggleDialogOpen(false);
    };

    const rings = ["All", "SDF", "MSIT", "WW"];

    const creatorList = jobCreationSyntheticStore.virtualTenants?.map(
      (vt) => vt.createdBy,
    );
    const creators = ["All", ...new Set(creatorList)];

    return (
      <div>
        <Dialog
          open={jobCreationSyntheticStore.isOpenSelectSyntheticUserDialog}
          onOpenChange={(_, data) => toggleDialogOpen(data.open)}
        >
          <DialogTrigger>
            <Button
              appearance="primary"
              disabled={
                jobCreationSyntheticStore.selectedUser !== "" ||
                jobCreationSyntheticStore.virtualTenants?.length === 0
              }
              onClick={() => toggleDialogOpen(true)}
            >
              Select test account
            </Button>
          </DialogTrigger>
          <DialogSurface className={styles.dialogSurface}>
            <DialogTitle className={styles.dialogTitle}>
              <div className={styles.dialogHeader}>
                <div>Select Test Account</div>
                <div
                  className={styles.closeButton}
                  onClick={() => toggleDialogOpen(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className={styles.searchArea}>
                <div className={styles.searchField}>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    placeholder="Search tenant or account name"
                    value={jobCreationSyntheticStore.searchTenant}
                    onChange={(e) => {
                      setSearchTenant(e.target.value);
                      const openAccountItems =
                        !jobCreationSyntheticStore.searchTenant
                          ? []
                          : filteredTenants
                              .filter((tenant) =>
                                filteredAccounts.some((va) =>
                                  tenant.physicalTenants.some((pt) =>
                                    pt.virtualAccounts.some(
                                      (account) => account.userId === va.userId,
                                    ),
                                  ),
                                ),
                              )
                              .map((tenant) => tenant.tenantId);
                      setOpenItems(openAccountItems);
                    }}
                    className={styles.searchInput}
                  />
                </div>
                <div className={styles.searchField}>
                  <Label>Tags</Label>
                  <Input
                    type="text"
                    placeholder="Select one or more tags"
                    value={jobCreationSyntheticStore.searchTag}
                    onChange={(e) => setSearchTag(e.target.value)}
                    className={styles.searchInput}
                  />
                </div>
                <div className={styles.searchField}>
                  <Label>Ring</Label>
                  <Combobox
                    value={jobCreationSyntheticStore.searchRing || "All"}
                    onOptionSelect={(_, data) => {
                      const ring = data.optionValue as string;
                      setSearchRing(ring === "All" ? "" : ring);
                    }}
                    defaultValue={"All"}
                    placeholder="Select a ring"
                  >
                    {rings.map((ring, index) => (
                      <Option key={index} value={ring}>
                        {ring}
                      </Option>
                    ))}
                  </Combobox>
                </div>
                <div className={styles.searchField}>
                  <Label>Created By</Label>
                  <Combobox
                    value={jobCreationSyntheticStore.searchCreator || "All"}
                    onOptionSelect={(_, data) => {
                      const creator = data.optionValue as string;
                      setSearchCreator(creator === "All" ? "" : creator);
                    }}
                    defaultValue={"All"}
                    placeholder="Select a creator"
                  >
                    {creators.map((creator, index) => (
                      <Option key={index} value={creator}>
                        {creator}
                      </Option>
                    ))}
                  </Combobox>
                </div>
              </div>
            </DialogTitle>
            <DialogBody className={styles.dialogBody}>
              <div className={styles.container}>
                <Table>
                  <TableBody className={styles.tableHeader}>
                    <TableRow className={styles.tableRow}>
                      <TableCell
                        colSpan={2}
                        className={styles.tableHeaderNamePaddingCell}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        className={styles.tableTagPaddingCell}
                      >
                        Tags
                      </TableCell>
                      <TableCell className={styles.tableHeaderCell}>
                        Ring
                      </TableCell>
                      <TableCell className={styles.tableHeaderCell}>
                        Created by
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {filteredTenants.length === 0 &&
                filteredAccounts.length === 0 ? (
                  <div className={styles.noContent}>
                    No tenant found matching the search criteria.
                  </div>
                ) : (
                  <TenantAccordion filteredTenants={filteredTenants} />
                )}
              </div>
            </DialogBody>
            <DialogActions className={styles.dialogActions}>
              <Button
                appearance="primary"
                disabled={
                  jobCreationSyntheticStore.selectedVirtualTenant ===
                    undefined ||
                  jobCreationSyntheticStore.selectedVirtualAccount === undefined
                }
                onClick={() => {
                  saveUserSelection();
                  toggleDialogOpen(false);
                }}
              >
                Select
              </Button>
              <Button onClick={() => toggleDialogOpen(false)}>Cancel</Button>
            </DialogActions>
          </DialogSurface>
        </Dialog>
      </div>
    );
  },
);

export default JobSelectSyntheticUserDialog;
