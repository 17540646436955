import { shorthands, tokens } from "@fluentui/react-components";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";

export const useGroupStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithMediumGap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      ...shorthands.gap("32px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    operationButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      ...shorthands.gap("16px"),
    },
    headerContainer: {
      ...shorthands.margin("20px", "0", "28px", "0"),
    },
    headerTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    listbox: {
      maxHeight: "300px",
    },
    dismissButton: {
      width: "200px",
    },
    fixWidthButton: {
      width: "200px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);
