import {
  Body1,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  Dropdown,
  Input,
  makeStyles,
  mergeClasses,
  Option,
  shorthands,
  Switch,
} from "@fluentui/react-components";
import {
  ArrowDownload24Regular,
  ArrowLeft24Regular,
  ArrowReset20Regular,
  ArrowRight24Regular,
  CaretDown12Filled,
  CaretUp12Filled,
  Filter24Regular,
  Search20Regular,
} from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { ExpandIcon } from "../../../../../components/Shared/Icons";
import { Tip } from "../../../../../components/Shared/Tip";
import { ConditionalWrapper } from "../../../../../components/Wrappers/ConditionalWrapper";
import {
  downloadData,
  exportedAssertionsData,
  parseRationale,
} from "../../../helpers/lmchecklistMetricsHelper";
import type { LMChecklistAssertion } from "../../../models/LMChecklistAssertionsResponse";
import {
  updatedLMCheckListFilters,
  updatedSorting,
} from "../../../mutators/jobDetailsMutators";
import {
  getEmptyFilters,
  getFilteredAssertions,
} from "../../../selectors/lmchecklist";
import {
  AssertionLevel,
  AssertionStatus,
  defaultLMCheckListFilters,
  jobDetailStore,
  LMCheckList_ITEMS_PER_PAGE,
} from "../../../store/jobDetailStore";
import { resultStore } from "../../../store/resultStore";
import { IcmButton } from "../../Other/IcmButton";
import { useSharedTableStyles } from "./shardTableStyles";
const useStyles = makeStyles({
  assertionCell: {
    width: "400px",
  },
  levelCell: {
    width: "100px",
  },
  scoreCell: {
    width: "50px",
  },
  ownerCell: {
    width: "250px",
  },
  sorting: {
    display: "flex",
    flexDirection: "column",
  },
  caretUp: {
    marginBottom: "-12px",
  },
  caretDown: {
    marginBottom: "-4px",
  },
  hoverRowStyle: {
    cursor: "pointer",
  },
  promptColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    ...shorthands.margin(0, 0, 0, 0),
  },
  promptContainer: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.margin("10px", 0, 0),
  },
  rowDropdown: {
    ...shorthands.padding("16px"),
  },
  sydneyReplyContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.margin("10px", 0, 0),
  },
  sydneyReply: {
    ...shorthands.padding(0, "16px", 0),
    ...shorthands.overflow("auto"),
    wordWrap: "break-word",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    maxHeight: "600px",
    width: "50%",
  },
  sydneyReplyExpName: {
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "32px",
  },
  semibold: {
    fontWeight: "500",
  },
  avgValueCell: {
    fontWeight: "600",
  },
  markdownSwitch: {
    float: "right",
    ...shorthands.margin("4px", 0),
  },
  dialogSurface: {
    wordWrap: "break-word",
    maxWidth: "750px",
    width: "100%",
  },
});

export const LMChecklistAssertionsTable = observer(() => {
  const sharedStyles = useSharedTableStyles();
  const styles = useStyles();

  const assertionCell = mergeClasses(sharedStyles.cell, styles.assertionCell);

  const levelCell = mergeClasses(sharedStyles.cell, styles.levelCell);

  const valueCell = mergeClasses(
    sharedStyles.cell,
    styles.scoreCell,
    sharedStyles.rightCell,
  );

  const ownerCell = mergeClasses(sharedStyles.cell, styles.ownerCell);

  const avgValueCell = mergeClasses(styles.avgValueCell, valueCell);

  const metricsHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
  );

  const metricsSubHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
    sharedStyles.subTitleCell,
  );

  const hoverRowStyle = mergeClasses(
    sharedStyles.rowStyle,
    styles.hoverRowStyle,
  );

  const getAssertionColor = (score: number | undefined) => {
    if (score !== undefined && score < 2) {
      return { backgroundColor: "rgba(221, 66, 66, 0.30)" };
    }
  };

  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(
    undefined,
  );

  const sorting = jobDetailStore.sorting;
  const filters = jobDetailStore.lmCheckListFilters;
  const assertions = jobDetailStore.lmchecklistAssertionsResponse ?? [];
  const icms = jobDetailStore.lmchecklistIcmsResponse;

  const numPages = computed(() =>
    Math.ceil(assertions.length / LMCheckList_ITEMS_PER_PAGE),
  ).get();

  const [page, setPage] = useState<number>(1);

  const sortedAssertions = getFilteredAssertions.get();

  const [isMarkdown, setIsMarkdown] = useState<boolean>(true);

  const [isPromptMarkdown, setIsPromptMarkdown] = useState(true);

  const sortIconVisibility = (sortedField: string, sortDirection: boolean) =>
    sorting === undefined ||
    (sortedField === sorting.field && sortDirection) ||
    sortedField !== sorting.field
      ? "visible"
      : "hidden";

  const getSortingIcon = (sortedField: string) => (
    <div className={styles.sorting}>
      {sortIconVisibility(sortedField, sorting?.ascending ?? true) ===
        "visible" && (
        <div className={styles.caretUp}>
          <CaretUp12Filled />
        </div>
      )}

      {sortIconVisibility(sortedField, !(sorting?.ascending ?? true)) ===
        "visible" && (
        <div className={styles.caretDown}>
          <CaretDown12Filled />
        </div>
      )}
    </div>
  );

  const handleSortingChange = (sortedField: string) => {
    if (sortedField === sorting.field) {
      updatedSorting({ field: sortedField, ascending: !sorting.ascending });
    } else {
      updatedSorting({ field: sortedField, ascending: sorting.ascending });
    }
  };

  const handleExpand = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(undefined);
      return;
    }
    setExpandedIndex(index);
  };

  const getExpandStyle = (index: number) =>
    expandedIndex === index
      ? mergeClasses(sharedStyles.expand, sharedStyles.expanded)
      : sharedStyles.expand;

  const handleAssertionStatusSelect = (status: AssertionStatus) => {
    if (
      filters.assertionStatus.length > 0 &&
      !filters.assertionStatus.includes("")
    ) {
      const newFilter = [...filters.assertionStatus];
      if (newFilter.includes(status)) {
        newFilter.splice(newFilter.indexOf(status), 1);
        if (newFilter.length === 0) {
          newFilter.push("");
        }
      } else {
        newFilter.push(status);
      }
      updatedLMCheckListFilters({ ...filters, assertionStatus: newFilter });
    } else {
      updatedLMCheckListFilters({ ...filters, assertionStatus: [status] });
    }
  };

  const handleAssertionLevelSelect = (level: AssertionLevel) => {
    if (
      filters.assertionLevel.length > 0 &&
      !filters.assertionLevel.includes("")
    ) {
      const newFilter = [...filters.assertionLevel];
      if (newFilter.includes(level)) {
        newFilter.splice(newFilter.indexOf(level), 1);
        if (newFilter.length === 0) {
          newFilter.push("");
        }
      } else {
        newFilter.push(level);
      }
      updatedLMCheckListFilters({ ...filters, assertionLevel: newFilter });
    } else {
      updatedLMCheckListFilters({ ...filters, assertionLevel: [level] });
    }
  };

  const handleFilterOptionSelect = (
    params:
      | { filter: "assertionStatus"; option: AssertionStatus }
      | { filter: "assertionLevel"; option: AssertionLevel },
  ) => {
    setPage(1);
    setExpandedIndex(undefined);
    if (params.filter === "assertionStatus") {
      handleAssertionStatusSelect(params.option);
    }
    if (params.filter === "assertionLevel") {
      handleAssertionLevelSelect(params.option);
    }
  };

  const slicedAssertions = computed(() => {
    const start = (page - 1) * LMCheckList_ITEMS_PER_PAGE;
    const end = page * LMCheckList_ITEMS_PER_PAGE;
    return sortedAssertions.slice(start, end);
  }).get();

  const renderPagination = () => (
    <div className={sharedStyles.filtersGroup}>
      <Button
        onClick={() => {
          setExpandedIndex(undefined);
          setPage(page - 1);
        }}
        disabled={page === 1}
        appearance="subtle"
        icon={<ArrowLeft24Regular />}
      />
      <span>{`${Math.min(page, numPages)} of ${numPages}`}</span>
      <Button
        onClick={() => {
          setExpandedIndex(undefined);
          setPage(page + 1);
        }}
        disabled={page === numPages || numPages === 0}
        appearance="subtle"
        icon={<ArrowRight24Regular />}
      />
    </div>
  );

  const renderFilterBar = () => {
    const assertionStatusOptions = Object.values(AssertionStatus);
    const assertionLevelOptions = Object.values(AssertionLevel);
    return (
      <div className={sharedStyles.filterBar}>
        <div className={sharedStyles.filtersGroup}>
          <Filter24Regular className={sharedStyles.filterIcon} />
          <Dropdown
            data-testid="assertionStatusDropdown"
            className={sharedStyles.filterBarItem}
            multiselect={true}
            placeholder="Status"
            value={filters.assertionStatus.join(", ")}
            selectedOptions={filters.assertionStatus}
            onOptionSelect={(_, option) =>
              handleFilterOptionSelect({
                filter: "assertionStatus",
                option: option.optionValue as AssertionStatus,
              })
            }
          >
            {assertionStatusOptions.map((option, index) => (
              <Option
                key={index}
                value={option}
                disabled={
                  (index % 2 === 1 &&
                    filters.assertionStatus.includes(
                      assertionStatusOptions[index - 1],
                    )) ||
                  (index % 2 === 0 &&
                    filters.assertionStatus.includes(
                      assertionStatusOptions[index + 1],
                    ))
                }
              >
                {option}
              </Option>
            ))}
          </Dropdown>
          <Dropdown
            className={sharedStyles.filterBarItem}
            data-testid="assertionLevelDropdown"
            multiselect={true}
            placeholder="Level"
            value={filters.assertionLevel.join(", ")}
            selectedOptions={filters.assertionLevel}
            onOptionSelect={(_, option) =>
              handleFilterOptionSelect({
                filter: "assertionLevel",
                option: option.optionValue as AssertionLevel,
              })
            }
          >
            {assertionLevelOptions.map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </Dropdown>
          <Input
            data-testid="assertionText"
            className={sharedStyles.filterBarItem}
            placeholder="Search assertions"
            aria-label="Search assertions"
            value={filters.assertionText}
            contentBefore={<Search20Regular />}
            onChange={(_, data) =>
              updatedLMCheckListFilters({
                ...filters,
                assertionText: data.value,
              })
            }
          />
          <Input
            data-testid="assertionOwner"
            className={sharedStyles.filterBarItem}
            placeholder="Search by owner"
            aria-label="Search by owner"
            value={filters.assertionOwner}
            contentBefore={<Search20Regular />}
            onChange={(_, data) =>
              updatedLMCheckListFilters({
                ...filters,
                assertionOwner: data.value,
              })
            }
          />
          {!getEmptyFilters() && (
            <Button
              className={sharedStyles.reset}
              onClick={() => {
                setPage(1);
                updatedLMCheckListFilters({
                  ...defaultLMCheckListFilters,
                  raiContent: true,
                });
                setExpandedIndex(undefined);
              }}
            >
              <ArrowReset20Regular className={sharedStyles.resetIcon} />
              <span>Reset</span>
            </Button>
          )}
          <Button
            title={"Download"}
            appearance="transparent"
            icon={<ArrowDownload24Regular />}
            onClick={() =>
              downloadData(
                exportedAssertionsData(sortedAssertions),
                resultStore.resultJob?.JobName ?? "",
              )
            }
          />
        </div>
        <div className={sharedStyles.filtersGroup}>
          <ConditionalWrapper
            condition={icms === undefined}
            Wrapper={Tip}
            wrapperProps={{
              content: "Filter disabled due to failure to query IcMs.",
              relationship: "label",
              withArrow: true,
            }}
          >
            <Checkbox
              label="Assertions with IcM only"
              checked={filters.assertionsWithIcmOnly}
              disabled={icms === undefined}
              onChange={() => {
                setPage(1);
                setExpandedIndex(undefined);
                updatedLMCheckListFilters({
                  ...filters,
                  assertionsWithIcmOnly: !filters.assertionsWithIcmOnly,
                  assertionsWithoutIcmOnly: false,
                });
              }}
            />
          </ConditionalWrapper>
          <ConditionalWrapper
            condition={icms === undefined}
            Wrapper={Tip}
            wrapperProps={{
              content: "Filter disabled due to failure to query IcMs.",
              relationship: "label",
              withArrow: true,
            }}
          >
            <Checkbox
              label="Assertions without IcM only"
              data-testid="assertionsWithoutIcmOnly"
              checked={filters.assertionsWithoutIcmOnly}
              disabled={icms === undefined}
              onChange={() => {
                setPage(1);
                setExpandedIndex(undefined);
                updatedLMCheckListFilters({
                  ...filters,
                  assertionsWithIcmOnly: false,
                  assertionsWithoutIcmOnly: !filters.assertionsWithoutIcmOnly,
                });
              }}
            />
          </ConditionalWrapper>
          <Checkbox
            label="Incomplete data only"
            checked={filters.incompleteDataOnly}
            onChange={() => {
              setPage(1);
              setExpandedIndex(undefined);
              updatedLMCheckListFilters({
                ...filters,
                incompleteDataOnly: !filters.incompleteDataOnly,
              });
            }}
          />
          <Checkbox
            label="Show Partial Failures"
            checked={filters.showPartialFailures}
            onChange={() => {
              setPage(1);
              setExpandedIndex(undefined);
              updatedLMCheckListFilters({
                ...filters,
                showPartialFailures: !filters.showPartialFailures,
              });
            }}
          />
          <div>
            <Checkbox
              checked={!filters.raiContent}
              onChange={() => {
                setPage(1);
                setExpandedIndex(undefined);
                updatedLMCheckListFilters({
                  ...filters,
                  raiContent: !filters.raiContent,
                });
              }}
            />
            <span style={{ color: "dimgray", fontSize: "14px" }}>
              &nbsp;Include Sensitive RAI Assertions (
              <a
                href="https://microsoft.sharepoint-df.com/:w:/t/CI_RAI/EXqNzIO-P6BNgR4QUshk8NkBPTERl-SHHnOGwSiftoZAbQ?e=zxfV9C"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disclaimer
              </a>
              )
            </span>
          </div>
          {renderPagination()}
        </div>
      </div>
    );
  };

  const renderTableHeader = () => (
    <thead className={sharedStyles.thead}>
      <tr className={sharedStyles.rowStyle}>
        <th
          className={metricsHeaderCell}
          style={{ width: "25%" }}
          rowSpan={2}
          onClick={() => handleSortingChange("query")}
        >
          <div className={sharedStyles.sortHeader}>
            Utterance
            {getSortingIcon("query")}
          </div>
        </th>
        <th
          className={metricsHeaderCell}
          style={{ width: "15%" }}
          rowSpan={2}
          onClick={() => handleSortingChange("segment")}
        >
          <div className={sharedStyles.sortHeader}>
            Segment
            {getSortingIcon("segment")}
          </div>
        </th>
        <th
          className={metricsHeaderCell}
          style={{ width: "35%" }}
          rowSpan={2}
          onClick={() => handleSortingChange("assertion")}
        >
          <div className={sharedStyles.sortHeader}>
            Assertion
            {getSortingIcon("assertion")}
          </div>
        </th>
        <th className={metricsHeaderCell} rowSpan={2}>
          <div className={sharedStyles.sortHeader}>Owner(s)</div>
        </th>
        <th
          className={metricsHeaderCell}
          rowSpan={2}
          onClick={() => handleSortingChange("level")}
        >
          <div className={sharedStyles.sortHeader}>
            Level
            {getSortingIcon("level")}
          </div>
        </th>
        <th className={metricsHeaderCell} colSpan={2}>
          Score
        </th>
      </tr>
      <tr className={sharedStyles.rowStyle}>
        <th
          className={metricsSubHeaderCell}
          onClick={() => handleSortingChange("control")}
        >
          <div className={sharedStyles.sortHeader}>
            Control
            {getSortingIcon("control")}
          </div>
        </th>
        <th
          className={metricsSubHeaderCell}
          onClick={() => handleSortingChange("experiment")}
        >
          <div className={sharedStyles.sortHeader}>
            Treatment
            {getSortingIcon("experiment")}
          </div>
        </th>
      </tr>
    </thead>
  );

  const renderReplyBlock = (
    response: string | undefined,
    reply: string | undefined,
    exp: string,
  ) => {
    const rationale = parseRationale(response);
    reply = reply !== undefined ? reply : "No response returned.";

    return (
      <div className={styles.sydneyReply}>
        <div className={styles.sydneyReplyExpName}>{exp}</div>
        <div style={{ marginBottom: "12px" }}>
          <span className={styles.semibold}>Rationale:</span>
          <ReactMarkdown>{rationale}</ReactMarkdown>
        </div>
        <div className={styles.semibold}>Sydney reply:</div>
        {isMarkdown ? (
          <ReactMarkdown>{reply}</ReactMarkdown>
        ) : (
          <div>{reply}</div>
        )}
      </div>
    );
  };

  const renderRowDropdown = (assertion: LMChecklistAssertion) => (
    <div className={styles.rowDropdown}>
      <div>
        <div>
          <span className={styles.semibold}>Utterance:</span> {assertion.query}
        </div>
        <div>
          <span className={styles.semibold}>Assertion:</span>{" "}
          {assertion.assertion}
        </div>
      </div>
      <div className={styles.sydneyReplyContainer}>
        {renderReplyBlock(
          assertion.response.control,
          assertion.sydneyReply.control,
          "Control",
        )}
        <Divider vertical />
        {renderReplyBlock(
          assertion.response.experiment,
          assertion.sydneyReply.experiment,
          "Treatment",
        )}
      </div>
      <div className={styles.sydneyReplyContainer}>
        <div className={styles.promptColumn}>
          {renderPromptDialog(assertion.prompt.control, "Control")}
        </div>
        {/* <Divider vertical /> */}
        <div className={styles.promptColumn}>
          {renderPromptDialog(assertion.prompt.experiment, "Treatment")}
        </div>
      </div>
      <Switch
        className={styles.markdownSwitch}
        checked={isMarkdown}
        onChange={() => setIsMarkdown(!isMarkdown)}
        label="Markdown"
      />
    </div>
  );

  const renderAssertionScore = (score: number | undefined) =>
    score !== undefined ? score : "Missing";

  const renderAssertionOwner = (owner: string | undefined) => {
    return owner ? owner : "None";
  };

  const renderPromptDialog = (
    prompt: string | undefined,
    treatment: string,
  ) => {
    if (prompt === undefined) {
      return "N/A";
    }
    return (
      <Dialog>
        <DialogTrigger disableButtonEnhancement>
          <Button
            onClick={(event) => {
              event?.stopPropagation();
            }}
          >
            {treatment} Prompt
          </Button>
        </DialogTrigger>
        <DialogSurface className={styles.dialogSurface}>
          <DialogBody>
            <DialogTitle>Prompt ({treatment})</DialogTitle>
            <DialogContent>
              {isPromptMarkdown ? (
                <ReactMarkdown>{prompt}</ReactMarkdown>
              ) : (
                <div>{prompt}</div>
              )}
            </DialogContent>
            <DialogActions>
              <Switch
                checked={isPromptMarkdown}
                onChange={() => setIsPromptMarkdown(!isPromptMarkdown)}
                label="Markdown"
              />
              <DialogTrigger disableButtonEnhancement>
                <Button
                  onClick={(event) => {
                    event?.stopPropagation();
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  };

  const renderAssertionsTable = () => (
    <tbody className={sharedStyles.tbody}>
      {slicedAssertions.map((assertion, index) => (
        <React.Fragment key={index}>
          <tr
            className={hoverRowStyle}
            style={getAssertionColor(assertion.score.experiment)}
            onClick={() => {
              handleExpand(index);
            }}
          >
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <div
                  className={getExpandStyle(index)}
                  onClick={(event) => {
                    handleExpand(index);
                    event?.stopPropagation();
                  }}
                >
                  <ExpandIcon />
                </div>
                <Tip withArrow content={assertion.query} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {assertion.query}
                  </Body1>
                </Tip>
              </div>
            </td>
            <td className={sharedStyles.cell}>
              <div className={sharedStyles.longTextWrapper}>
                <Tip withArrow content={assertion.segment} relationship="label">
                  <Body1 className={sharedStyles.longText}>
                    {assertion.segment}
                  </Body1>
                </Tip>
              </div>
            </td>
            <td className={assertionCell}>
              <div className={sharedStyles.longTextWrapper}>
                <Tip
                  withArrow
                  content={assertion.assertion}
                  relationship="label"
                >
                  <Body1 className={sharedStyles.longText}>
                    {assertion.assertion}
                  </Body1>
                </Tip>
                {assertion.icm && <IcmButton icm={assertion.icm} />}
              </div>
            </td>
            <td className={ownerCell}>
              {renderAssertionOwner(assertion.owner)}
            </td>
            <td className={levelCell}>{assertion.level}</td>
            <td className={valueCell}>
              {renderAssertionScore(assertion.score.control)}
            </td>
            <td className={valueCell}>
              {renderAssertionScore(assertion.score.experiment)}
            </td>
          </tr>
          {index === expandedIndex && (
            <tr key={`${index}-dropdown`} className={sharedStyles.rowStyle}>
              <td className={sharedStyles.cell} colSpan={7}>
                {renderRowDropdown(assertion)}
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
  );

  const getCurrentFiltersString = () => {
    const { assertionStatus, assertionLevel } = filters;
    const statusString = assertionStatus.includes("")
      ? ""
      : `${assertionStatus.join(", ")}`;
    const levelString = assertionLevel.includes("")
      ? ""
      : `${assertionLevel.join(", ")}`;

    if (statusString && levelString) {
      return ` (${statusString}, ${levelString})`;
    }

    return statusString || levelString
      ? ` (${statusString}${levelString})`
      : "";
  };

  const renderAssertionsTableFooter = () => (
    <tfoot>
      <tr className={sharedStyles.rowStyle}>
        <td className={avgValueCell} colSpan={6}>
          Total assertions{getCurrentFiltersString()}:
        </td>
        <td className={avgValueCell}>{sortedAssertions.length}</td>
      </tr>
    </tfoot>
  );

  return (
    <>
      {renderFilterBar()}
      <table className={sharedStyles.table}>
        {renderTableHeader()}
        {renderAssertionsTable()}
        {renderAssertionsTableFooter()}
      </table>
    </>
  );
});
