import { Dropdown, Option } from "@fluentui/react-components";
import { observer } from "mobx-react";
import {
  updateSelectBingFileAction,
  updateSelectedFilterAction,
} from "../../../actions/resultActions";
import { resultStore } from "../../../store/resultStore";
import { useResultViewStyles } from "../sharedStyles";

interface IBingDropDownProps {
  options: Map<string, string[]>;
  multiSelect: string[];
}

export const BingDropDown = observer((props: IBingDropDownProps) => {
  const styles = useResultViewStyles();

  const outputfiles = resultStore.bingJobOutputFiles;
  const dropdownOptions = props.options;

  if (outputfiles === undefined) {
    return <div></div>;
  }

  const renderFolder = () => {
    const folders = [...new Set(outputfiles.map((item) => item.folderName))];
    if (resultStore.seletedBingFile === undefined && folders.length > 0) {
      updateSelectBingFileAction(folders[0]);
    }

    if (folders.length <= 1) {
      return <></>;
    }

    return (
      <div className={styles.filterContainer} key="Folders">
        <div className={styles.filterLabel}>Data set</div>
        <Dropdown
          key={"Folders"}
          value={resultStore.seletedBingFile ?? folders[0]}
          onOptionSelect={(_, option) => {
            updateSelectBingFileAction(option.optionValue ?? folders[0]);
          }}
        >
          {folders.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Dropdown>
      </div>
    );
  };

  const renderSingleDropdown = (key: string, values: string[]) => {
    return (
      <Dropdown
        key={key}
        value={resultStore.selectedFilter.get(key) ?? "Unselected"}
        selectedOptions={[resultStore.selectedFilter.get(key) ?? "Unselected"]}
        onOptionSelect={(_, option) => {
          if (option.optionValue !== "Unselected") {
            updateSelectedFilterAction(key, option.optionValue);
          } else {
            updateSelectedFilterAction(key, undefined);
          }
        }}
      >
        <Option key="Unselected" value="Unselected">
          Unselected
        </Option>
        {values.map((item) => (
          <Option className={styles.dropdownOption} key={item} value={item}>
            {item}
          </Option>
        ))}
      </Dropdown>
    );
  };

  const renderMultiSelectDropdown = (key: string, values: string[]) => {
    return (
      <Dropdown
        multiselect={true}
        key={key}
        value={resultStore.selectedFilter.get(key)}
        selectedOptions={resultStore.selectedFilter.get(key)?.split(",") ?? []}
        onOptionSelect={(_, data) => {
          if (data.selectedOptions.length === 0) {
            updateSelectedFilterAction(key, undefined);
          } else {
            updateSelectedFilterAction(key, data.selectedOptions.join(","));
          }
        }}
      >
        {values.map((item) => (
          <Option className={styles.dropdownOption} key={item} value={item}>
            {item}
          </Option>
        ))}
      </Dropdown>
    );
  };
  return (
    <div className={styles.rowWithSmallerGap}>
      {renderFolder()}
      {Array.from(dropdownOptions.keys()).map((key) => {
        const values = dropdownOptions.get(key);
        if (values !== undefined && values.length > 0) {
          return (
            <div className={styles.filterContainer} key={key}>
              <div className={styles.filterLabel}>{key}</div>
              {props.multiSelect.includes(key)
                ? renderMultiSelectDropdown(key, values)
                : renderSingleDropdown(key, values)}
            </div>
          );
        }
      })}
    </div>
  );
});
