import { Button, Field, tokens } from "@fluentui/react-components";
import {
  Add16Regular,
  Delete16Regular,
  Edit16Regular,
  Warning20Filled,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import {
  addEditSubJobAction,
  deleteSubJobAction,
} from "../../../actions/jobGroupActions";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { GeneralTable } from "../../JobList/GeneralTable";
import { useGroupStyles } from "../groupStyles";

export const GroupJobView = observer(() => {
  const styles = useGroupStyles();
  const emptyFunction = () => void 0;

  const isPredefined =
    jobGroupCreationStore.selectedGroupTemplate?.Type === "Predefined";

  return (
    <div className={styles.block}>
      <Field
        required
        label={<span className={styles.blockTitle}>Jobs</span>}
      ></Field>
      <GeneralTable
        loadMore={emptyFunction}
        hasMore={false}
        rows={jobGroupCreationStore.jobs}
        columns={[
          {
            title: "Template",
            render: (job) => <>{job.templateName}</>,
            weight: 3,
            minWidth: "250px",
          },
          {
            title: "Job Name",
            render: (job) => (
              <>
                {job.configuration === undefined ? (
                  <>
                    {" "}
                    <Warning20Filled
                      color={tokens.colorStatusWarningForeground1}
                    />
                    {"  "}
                    No Job Added{" "}
                  </>
                ) : (
                  <>{job.jobName}</>
                )}
              </>
            ),
            weight: 3,
            minWidth: "400px",
          },
          {
            title: "Action",
            render: (job) => (
              <>
                {job.configuration ? (
                  <Button
                    icon={<Edit16Regular />}
                    onClick={() => addEditSubJobAction(job.localId, "edit")}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    icon={<Add16Regular />}
                    onClick={() => addEditSubJobAction(job.localId, "add")}
                  >
                    Add
                  </Button>
                )}
                {!isPredefined && (
                  <Button
                    icon={<Delete16Regular />}
                    onClick={() => deleteSubJobAction(job.localId)}
                  >
                    Delete
                  </Button>
                )}
              </>
            ),
            weight: 2,
            minWidth: "200px",
          },
        ]}
      />
      {!isPredefined && (
        <Button
          className={styles.fixWidthButton}
          icon={<Add16Regular />}
          onClick={() => addEditSubJobAction("New Job", "add")}
        >
          Add
        </Button>
      )}
    </div>
  );
});
