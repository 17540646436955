import { action } from "satcheljs";
import type { JobGroupTemplate } from "sydneyeval-shared";

export const createJobGroupAction = action("createJobGroupAction", () => ({}));

export const updateSelectedGroupTemplatesAction = action(
  "updateSelectedGroupTemplatesAction",
  (selected: JobGroupTemplate) => ({ selected }),
);

export const addEditSubJobAction = action(
  "addEditSubJobAction",
  (localId: string, mode: "add" | "edit") => ({ localId, mode }),
);

export const deleteSubJobAction = action(
  "deleteSubJobAction",
  (localId: string) => ({ localId }),
);

export const saveSubJobAction = action("saveSubJobAction", () => ({}));

export const submitJobGroupAction = action("submitJobGroupAction", () => ({}));
